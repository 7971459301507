import React from 'react';
import { Line, Text as KonvaText, Group, Rect } from 'react-konva';

const MeasurementLine = (props) => {
    // Add safety check
    if (!props) return null;

    const lineStyle = {
        stroke: "#D138E5",
        strokeWidth: 1,
        opacity: 0.8
    };

    const capStyle = {
        stroke: "#D138E5",
        strokeWidth: 1,
        opacity: 0.8
    };

    const textStyle = {
        fill: "white",
        fontSize: 14,
        align: "left",
        fontFamily: "Arial"
    };

    const boxStyle = {
        fill: "#D138E5",
        cornerRadius: 4,
        opacity: 0.9
    };

    const boxPadding = 10;
    const boxHeight = 24;
    const verticalOffset = -30;
    const capLength = 8;
    const offset = 10;
    
    if (props.isScreen) {
        const source = props.source; // Get source dimensions
        return (
            <Group>
                {/* Top measurement */}
                <Line
                    points={[props.top.start.x, props.top.start.y, props.top.end.x, props.top.end.y]}
                    {...lineStyle}
                />
                {/* Top caps */}
                <Line
                    points={[
                        props.top.start.x - capLength/2,
                        props.top.start.y,
                        props.top.start.x + capLength/2,
                        props.top.start.y
                    ]}
                    {...capStyle}
                />
                <Line
                    points={[
                        props.top.end.x - capLength/2,
                        props.top.end.y,
                        props.top.end.x + capLength/2,
                        props.top.end.y
                    ]}
                    {...capStyle}
                />
                {/* Top text box */}
                {(() => {
                    const text = `${Math.round(props.top.distance)}px`;
                    const textWidth = text.length * 8;
                    const boxWidth = textWidth + boxPadding * 2;
                    return (
                        <>
                            <Rect
                                x={source.x + source.width/2 + boxWidth/2 - (offset*2)}
                                y={source.y - boxHeight - offset}
                                width={boxWidth}
                                height={boxHeight}
                                {...boxStyle}
                            />
                            <KonvaText
                                x={source.x + source.width/2 + boxWidth/2 + boxPadding - (offset*2)}
                                y={source.y - boxHeight - offset + 5}
                                text={text}
                                width={textWidth}
                                {...textStyle}
                            />
                        </>
                    );
                })()}

                {/* Bottom measurement */}
                <Line
                    points={[props.bottom.start.x, props.bottom.start.y, props.bottom.end.x, props.bottom.end.y]}
                    {...lineStyle}
                />
                {/* Bottom caps */}
                <Line
                    points={[
                        props.bottom.start.x - capLength/2,
                        props.bottom.start.y,
                        props.bottom.start.x + capLength/2,
                        props.bottom.start.y
                    ]}
                    {...capStyle}
                />
                <Line
                    points={[
                        props.bottom.end.x - capLength/2,
                        props.bottom.end.y,
                        props.bottom.end.x + capLength/2,
                        props.bottom.end.y
                    ]}
                    {...capStyle}
                />
                {/* Bottom text box */}
                {(() => {
                    const text = `${Math.round(props.bottom.distance)}px`;
                    const textWidth = text.length * 8;
                    const boxWidth = textWidth + boxPadding * 2;
                    return (
                        <>
                            <Rect
                                x={source.x + source.width/2 + boxWidth/2 - (offset*2)}
                                y={source.y + source.height + offset}
                                width={boxWidth}
                                height={boxHeight}
                                {...boxStyle}
                            />
                            <KonvaText
                                x={source.x + source.width/2 + boxWidth/2 + boxPadding - (offset*2)}
                                y={source.y + source.height + offset + 5}
                                text={text}
                                width={textWidth}
                                {...textStyle}
                            />
                        </>
                    );
                })()}

                {/* Left measurement */}
                <Line
                    points={[props.left.start.x, props.left.start.y, props.left.end.x, props.left.end.y]}
                    {...lineStyle}
                />
                {/* Left caps */}
                <Line
                    points={[
                        props.left.start.x,
                        props.left.start.y - capLength/2,
                        props.left.start.x,
                        props.left.start.y + capLength/2
                    ]}
                    {...capStyle}
                />
                <Line
                    points={[
                        props.left.end.x,
                        props.left.end.y - capLength/2,
                        props.left.end.x,
                        props.left.end.y + capLength/2
                    ]}
                    {...capStyle}
                />
                {/* Left text box */}
                {(() => {
                    const text = `${Math.round(props.left.distance)}px`;
                    const textWidth = text.length * 8;
                    const boxWidth = textWidth + boxPadding * 2;
                    return (
                        <>
                            <Rect
                                x={source.x - boxWidth - offset}
                                y={source.y + source.height/2 - boxHeight - offset}
                                width={boxWidth}
                                height={boxHeight}
                                {...boxStyle}
                            />
                            <KonvaText
                                x={source.x - boxWidth - offset + boxPadding}
                                y={source.y + source.height/2 - boxHeight - offset + boxPadding/2}
                                text={text}
                                width={textWidth}
                                {...textStyle}
                            />
                        </>
                    );
                })()}

                {/* Right measurement */}
                <Line
                    points={[props.right.start.x, props.right.start.y, props.right.end.x, props.right.end.y]}
                    {...lineStyle}
                />
                {/* Right caps */}
                <Line
                    points={[
                        props.right.start.x,
                        props.right.start.y - capLength/2,
                        props.right.start.x,
                        props.right.start.y + capLength/2
                    ]}
                    {...capStyle}
                />
                <Line
                    points={[
                        props.right.end.x,
                        props.right.end.y - capLength/2,
                        props.right.end.x,
                        props.right.end.y + capLength/2
                    ]}
                    {...capStyle}
                />
                {/* Right text box */}
                {(() => {
                    const text = `${Math.round(props.right.distance)}px`;
                    const textWidth = text.length * 8;
                    const boxWidth = textWidth + boxPadding * 2;
                    return (
                        <>
                            <Rect
                                x={source.x + source.width + offset}
                                y={source.y + source.height/2 - boxHeight - offset}
                                width={boxWidth}
                                height={boxHeight}
                                {...boxStyle}
                            />
                            <KonvaText
                                x={source.x + source.width + offset + boxPadding}
                                y={source.y + source.height/2 - boxHeight  - offset + boxPadding/2}
                                text={text}
                                width={textWidth}
                                {...textStyle}
                            />
                        </>
                    );
                })()}
            </Group>
        );
    }

    // Regular measurement (non-screen)
    if (!props.start || !props.end) return null;

    const horizontalDistance = Math.abs(props.end.x - props.start.x);
    const verticalDistance = Math.abs(props.end.y - props.start.y);
    const isVertical = verticalDistance > horizontalDistance;
    
    const lineX = isVertical ? props.start.x : null;
    const lineY = !isVertical ? props.start.y : null;
    
    const measurementText = `${Math.round(props.distance)}px`;
    const textWidth = measurementText.length * 8;
    const boxWidth = textWidth + boxPadding * 2;
    
    return (
        <Group>
            <Line
                points={[
                    isVertical ? lineX : props.start.x,
                    !isVertical ? lineY : props.start.y,
                    isVertical ? lineX : props.end.x,
                    !isVertical ? lineY : props.end.y
                ]}
                {...lineStyle}
            />
            {/* Start cap */}
            <Line
                points={[
                    isVertical ? lineX - capLength/2 : props.start.x,
                    !isVertical ? lineY - capLength/2 : props.start.y,
                    isVertical ? lineX + capLength/2 : props.start.x,
                    !isVertical ? lineY + capLength/2 : props.start.y
                ]}
                {...lineStyle}
            />
            {/* End cap */}
            <Line
                points={[
                    isVertical ? lineX - capLength/2 : props.end.x,
                    !isVertical ? lineY - capLength/2 : props.end.y,
                    isVertical ? lineX + capLength/2 : props.end.x,
                    !isVertical ? lineY + capLength/2 : props.end.y
                ]}
                {...lineStyle}
            />
            {/* Background rectangle for text */}
            <Rect
                x={isVertical ? lineX + 10 : (props.start.x + props.end.x) / 2 - boxWidth/2}
                y={isVertical ? (props.start.y + props.end.y) / 2 - boxHeight/2 : lineY - 30}
                width={boxWidth}
                height={boxHeight}
                {...boxStyle}
            />
            <KonvaText
                x={isVertical ? lineX + 10 + boxPadding : (props.start.x + props.end.x) / 2 - boxWidth/2 + boxPadding}
                y={isVertical ? (props.start.y + props.end.y) / 2 - 7 : lineY - 25}
                text={measurementText}
                {...textStyle}
                width={textWidth}
            />
        </Group>
    );
};

export default MeasurementLine;