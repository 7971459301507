import Prism from 'prismjs';
import 'prismjs/components/prism-jsx';

const elementConfig = {
  textfield: {
    tag: 'input',
    props: {
      value: '{inputValue}',
      onChange: '{handleInputChange}',
      placeholder: 'placeholder'
    },
    needsState: true
  },
  button: {
    tag: 'button',
    props: {
      onClick: '{handleClick}'
    },
    content: 'value',
    needsClick: true
  },
  text: {
    tag: 'p',
    content: 'value'
  },
  image: {
    tag: 'img',
    props: {
      src: 'https://images.unsplash.com/photo-1515266591878-f93e32bc5937?q=80&w=2592&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
      alt: 'image_name'
    }
  },
  switch: {
    tag: 'div',
    props: {
      onClick: '{() => setIsChecked(!isChecked)}',
    },
    needsState: true,
    renderContent : (element) => {
        return `
        <div style={{
            width: ${element.height}, 
            height: '100%', 
            backgroundColor: '${element.notch_colour}',
            transition: 'ease-in-out',
            transitionDuration: '.3s',
            borderRadius: 100,
            transform: isChecked ? \`translateX(\${${element.width} - ${element.height} - 20}px)\` : 'translateX(0px)',
        }}/>`    
    }
  },
  container: {
    tag: 'div',
    hasChildren: true
  }
};

const extractStyles = (element, isChild = false) => {
  const styleMap = {
    width: element.width ? `${element.width}` : null,
    height: element.height ? `${element.height}` : null,
    fontFamily: element.font ? `"${element.font}"` : null,
    fontSize: element.font_size ? `${element.font_size}` : null,
    color: element.font_colour ? `"${element.font_colour}"` : null,
    backgroundColor: element.fill ? `"${element.fill}"` : null,
    borderWidth: element.border_width ? `${element.border_width}` : 0,
    borderColor: element.border_colour ? `"${element.border_colour}"` : null,
    borderStyle: element.border_width ? `"solid"` : null,
    borderTopLeftRadius: element.border_radius_tl ? `${element.border_radius_tl}` : null,
    borderTopRightRadius: element.border_radius_tr ? `${element.border_radius_tr}` : null,
    borderBottomLeftRadius: element.border_radius_bl ? `${element.border_radius_bl}` : null,
    borderBottomRightRadius: element.border_radius_br ? `${element.border_radius_br}` : null,
    position: isChild ? `"absolute"` : `"relative"`,
    left: isChild && element.xpos ? `${element.xpos}` : null,
    top: isChild && element.ypos ? `${element.ypos}` : null,
    objectFit: element.aspect_ratio ? `"${element.aspect_ratio}"` : null,
    lineHeight: element.line_height ? `${element.line_height}` : null,
    letterSpacing: element.letter_spacing ? `${element.letter_spacing}` : null,
    textAlign: element.text_alignment ? `"${element.text_alignment}"` : null,
  };

  // Switch-specific styles
  if (element.type === 'switch') {
    styleMap.boxSizing = `"border-box"`;
    styleMap.borderWidth = null;
    styleMap.borderColor = null;
    styleMap.borderStyle = null;
    styleMap.padding = 10;
    styleMap.backgroundColor = `isChecked ? "${element.active_colour}" : "${element.disabled_colour}"`;
    styleMap.transition = `"ease-in-out"`;
    styleMap.transitionDuration = `".3s"`;
  }

  if(element.type === 'text'){
    styleMap.width = element.width ? `${element.width+1}` : null;
  }

  const styles = Object.entries(styleMap)
    .filter(([_, value]) => value != null)
    .map(([key, value]) => `     ${key}: ${value}`)
    .join(',\n');

  return styles || '// Add custom styles here';
};

const generateProps = (config, element) => {
    if (!config.props) return '';
    
    const props = Object.entries(config.props)
      .map(([key, value]) => {
        if (value.startsWith('{')) {
          return `${key}=${value}`;
        }
        // If value starts with http, treat it as a literal URL
        if (value.startsWith('http')) {
          return `${key}="${value}"`;
        }
        const propValue = element[value];
        return propValue ? `${key}="${propValue}"` : null;
      })
      .filter(Boolean)
      .join(' ');
  
    return props ? ` ${props}` : '';
  };

export const extractCoreComponent = (element) => {
  const config = elementConfig[element.type];
  if (!config) return null;

  const styles = extractStyles(element);
  const props = generateProps(config, element);
  
  let content = '';
  if (config.hasChildren && element.children?.length) {
    content = element.children
      .map(child => {
        const childConfig = elementConfig[child.type];
        if (!childConfig) return '';
        
        const childStyles = extractStyles(child, true);
        const childProps = generateProps(childConfig, child);
        const childContent = child[childConfig.content] || '';
        
    return `<${childConfig.tag}${childProps} style={{
${childStyles}
    }}>
      ${childContent}
    </${childConfig.tag}>`;
      })
      .filter(Boolean)
      .join('\n');
  } else if (config.renderContent) {
    content = config.renderContent(element);
  }else if (config.content && element[config.content]) {
    content = element[config.content];
  }

  return `<${config.tag}${props} style={{
${styles}
  }}>
  ${content}
</${config.tag}>`;
};

export const generateComponentCode = (element) => {
  // Handle design system components
  if (element.type === 'designSystem') {
    // Clean up the code by removing the wrapper function and parentheses
    const cleanCode = element.code
      .replace(/^\(\)\s*=>\s*{/, '')     // Remove leading arrow function
      .replace(/return\s*/, '')           // Remove return statement
      .replace(/}\s*$/, '')              // Remove trailing brace
      .replace(/^\s*\(\s*/, '')          // Remove opening parenthesis
      .replace(/\s*\)\s*$/, '')          // Remove closing parenthesis
      .trim();                           // Clean up whitespace

    // Add positioning styles
    const positionedCode = cleanCode.replace(
      /style=\{\{/,
      `style={{
    position: 'absolute',
    left: ${element.xpos}px,
    top: ${element.ypos}px,`
    );

    return `// Design System Component: ${element.component_name}
${positionedCode}`;
  }

  // Handle container with mixed children
  if (element.type === 'container' && element.children?.length > 0) {
    const childrenCode = element.children
      .map(child => {
        if (child.type === 'designSystem') {
          // Clean up design system component code
          const cleanCode = child.code
            .replace(/^\(\)\s*=>\s*{/, '')     // Remove leading arrow function
            .replace(/return\s*/, '')           // Remove return statement
            .replace(/}\s*$/, '')              // Remove trailing brace
            .replace(/^\s*\(\s*/, '')          // Remove opening parenthesis
            .replace(/\s*\)\s*$/, '')          // Remove closing parenthesis
            .trim();                           // Clean up whitespace

          // Add positioning styles
          const positionedCode = cleanCode.replace(
            /style=\{\{/,
            `style={{
    position: 'absolute',
    left: ${child.xpos}px,
    top: ${child.ypos}px,`
          );

          return `  // ${child.component_name}
  ${positionedCode}`;
        } else {
          const childJsx = extractCoreComponent(child);
          return `  // ${child.type}
  ${childJsx}`;
        }
      })
      .join('\n\n');

    return `// Container with Components
<div style={{
  width: ${element.width}px,
  height: ${element.height}px,
  position: 'relative'
}}>
${childrenCode}
</div>`;
  }

  const componentName = element.type.charAt(0).toUpperCase() + element.type.slice(1) + 'Component';
  const jsxContent = extractCoreComponent(element);
  
  if (!jsxContent) {
    throw new Error('Failed to generate component code');
  }

  let stateCode = '';
  let importsCode = `import React, { useState } from \'react\';\n`;
  
  // Generate state and handlers based on element type and children
  const needsTextField = element.type === 'textfield' || 
    element.children?.some(child => child.type === 'textfield');
  const needsSwitch = element.type === 'switch' || 
    element.children?.some(child => child.type === 'switch');
  const needsButton = element.type === 'button' || 
    element.children?.some(child => child.type === 'button');

  if (needsTextField) {
    stateCode += 'const [inputValue, setInputValue] = useState("");\n  ';
    stateCode += 'const handleInputChange = (e) => setInputValue(e.target.value);\n  ';
  }

  if (needsSwitch) {
    stateCode += 'const [isChecked, setIsChecked] = useState(false);\n  ';
  }

  if (needsButton) {
    stateCode += 'const handleClick = () => console.log("Button clicked");\n  ';
  }

  // Add hover effects for buttons
  let useEffectCode = '';
  if (element.type === 'button' || element.children?.some(child => child.type === 'button')) {
    useEffectCode = `
  useEffect(() => {
    const button = document.querySelector('button');
    if (button) {
      button.addEventListener('mouseenter', () => {
        button.style.backgroundColor = "${element.hover_fill}";
        button.style.borderColor = "${element.hover_border_colour}";
        button.style.borderWidth = "${element.hover_border_width}px";
      });
      button.addEventListener('mouseleave', () => {
        button.style.backgroundColor = "${element.fill}";
        button.style.borderColor = "${element.border_colour}";
        button.style.borderWidth = "${element.border_width}px";
      });
    }
  }, []);\n  `;
    importsCode = 'import React, { useState, useEffect } from \'react\';\n';
  }

  const code = `${importsCode}
function ${componentName} (){
  ${stateCode}${useEffectCode}return (
  ${jsxContent}
  );
};

export default ${componentName};`;

return code;
};