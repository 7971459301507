import './centerpanel.css';
import { TokensIcon, MixIcon } from '@radix-ui/react-icons'
import * as Popover from '@radix-ui/react-popover';
import { useEditorContext } from '../../../context/editor/editorcontext';
import { useState } from 'react';
import ElementsModal from './elementsModal/elementsModal';
import DesignSystemModal from './designSystemModal/designSystemModal';


function CenterPanel(){

    const editorContext = useEditorContext();
    const [openPanel, setOpenPanel] = useState(false);

    const handleOnClick = (type) => {
        //add element on button click        
        editorContext.addElement(type);
        setOpenPanel(false);
    }

    return(
        <div className='cpcontainer'>
            <ElementsModal/>
            <DesignSystemModal/>
        </div>
    );
}

export default CenterPanel;