import React, {useState, useEffect} from "react";
import { Group, Line } from 'react-konva';
import CoreDesignSystem from "./core/coredesignsystem";
import { useEditorContext } from "../../../context/editor/editorcontext";
import ContextMenu from "../../ui/contextmenu/contextmenu";
import { rectInRect } from "../utils/elementinscreen";
import { calcWidthHeight } from "../../../utils/editor/calcWidthHeight";
import PrototypeMenu from "../../ui/prototypemenu/prototypemenu";
import { ElementOverContainer } from "../utils/elementovercontainer";
import { onDragMoveOS } from "../utils/objectsnapping";

function DesignSystem({item, stageRef}) {
    const [selectedId, setSelectedId] = useState(null);
    const editorContext = useEditorContext();
    const [domensions, setDomensions] = useState({x: 0, y: 0});
    const [hLines, setHLines] = useState([]);
    const [vLines, setVLines] = useState([]);
    const [width, setWidth] = useState(item.width);
    const [height, setHeight] = useState(item.height);
    const [xpos, setXpos] = useState(item.xpos);
    const [ypos, setYpos] = useState(item.ypos); 
    const [contextMenuOptions, setContextMenuOptions] = useState({
        x: item.xpos,
        y: item.ypos,
    });

    useEffect(()=>{
        if(editorContext.isCanvasSelected){
            setSelectedId(null);
        }
    },[editorContext.isCanvasSelected]);

    useEffect(() => {
        const handleKeyDown = (e) => {
            // More thorough check for code editor focus
            const isCodeEditorFocused = (
                document.activeElement?.classList.contains('npm__react-simple-code-editor__textarea') ||
                document.activeElement?.classList.contains('prism-code') ||
                document.activeElement?.closest('.prism-code') !== null
            );

            if (isCodeEditorFocused) {
                return;
            }

            if (item.id !== selectedId || selectedId !== editorContext.activeElement?.id) {
                return;
            }

            const moveAmount = e.shiftKey ? 10 : 1;
            let newX = xpos;
            let newY = ypos;

            switch (e.key) {
                case 'ArrowLeft':
                    newX -= moveAmount;
                    break;
                case 'ArrowRight':
                    newX += moveAmount;
                    break;
                case 'ArrowUp':
                    newY -= moveAmount;
                    break;
                case 'ArrowDown':
                    newY += moveAmount;
                    break;
                default:
                    return;
            }

            e.preventDefault();
            setXpos(newX);
            setYpos(newY);

            const props = {
                from: 'keyMove',
                id: item.id,
                grouped: item.grouped,
                grouped_by: item.grouped_by,
                xpos: newX,
                ypos: newY,
                type: 'designSystem'
            };
            editorContext.updateElement(props);
        };

        window.addEventListener('keydown', handleKeyDown);
        return () => window.removeEventListener('keydown', handleKeyDown);
    }, [selectedId, editorContext.activeElement?.id, xpos, ypos, item.id, item.grouped, item.grouped_by]);

    // console.log('System ID:', item.systemId);
    // console.log('Component Name:', item.name);
    // console.log('Component:', item.component);

    return (
        <Group>
            <>
                {editorContext.screens.some((e) => e.isVisible === true && e.id == item.screen) ? (
                    <>
                        <CoreDesignSystem 
                            stageRef={stageRef}
                            keyVal={item.id}
                            shapeProps={item}
                            setDomensions={setDomensions}
                            isSelected={item.id == selectedId && selectedId == editorContext.activeElement?.id}
                            setSelectedId={setSelectedId}
                            elWidth={width}
                            elHeight={height}
                            onSelect={(e) => {
                                setSelectedId(item.id);  
                                editorContext.setCurrentActiveElement(item);
                                setContextMenuOptions({x: item.xpos, y: item.ypos});
                            }}
                            onDragMove={(e) => {  
                                setSelectedId(item.id);
                                editorContext.setCurrentActiveElement(item);
                                if(item.id != selectedId && selectedId != editorContext.activeElement?.id) {
                                    return;
                                }

                                e.cancelBubble = true;

                                const snapLines = onDragMoveOS(stageRef, e.target);
                                if (snapLines.length > 0) {
                                    setVLines(snapLines
                                        .filter(line => line.type === 'vertical')
                                        .map(line => ({
                                            points: [0, -6000, 0, 6000],
                                            x: line.x,
                                            y: 0,
                                            stroke: '#D138E5',
                                            strokeWidth: 1,
                                        }))
                                    );
                                    
                                    setHLines(snapLines
                                        .filter(line => line.type === 'horizontal')
                                        .map(line => ({
                                            points: [-6000, 0, 6000, 0],
                                            x: 0,
                                            y: line.y,
                                            stroke: '#D138E5',
                                            strokeWidth: 1,
                                        }))
                                    );
                                } else {
                                    setVLines([]);
                                    setHLines([]);
                                }

                                const x = e.target.attrs.x;
                                const y = e.target.attrs.y;
                                setXpos(x);
                                setYpos(y);
                            }}
                            onDragEnd={async(e) => {
                                if(item.id != selectedId && selectedId != editorContext.activeElement?.id) {
                                    return;
                                }

                                e.cancelBubble = true;
                                setVLines([]);
                                setHLines([]);

                                const currentScreen = editorContext.screens.filter((i) => i.isVisible == true);
                                const currentElement = e.target.attrs;
                                const isContainer = await ElementOverContainer(stageRef, editorContext.element, item);

                                const containerPos = item.grouped ? 
                                    editorContext.element.find(el => el.id === item.grouped_by) : 
                                    null;

                                const absoluteX = item.grouped ? 
                                    containerPos.xpos + currentElement.x : 
                                    currentElement.x;

                                const absoluteY = item.grouped ? 
                                    containerPos.ypos + currentElement.y : 
                                    currentElement.y;

                                const screenH = currentScreen[0].scroll_height ?? currentScreen[0].height;
                                const r1 = { 
                                    left: currentScreen[0].xpos,
                                    top: currentScreen[0].ypos,
                                    right: currentScreen[0].xpos + currentScreen[0].width,
                                    bottom: currentScreen[0].ypos + screenH
                                };

                                const r2 = { 
                                    left: absoluteX,
                                    top: absoluteY,
                                    right: absoluteX + item.width,   
                                    bottom: absoluteY + item.height
                                };

                                const isElementinScreen = rectInRect(r1, r2);
                                const x = e.target.attrs.x;
                                const y = e.target.attrs.y;
                                const props = {
                                    from: 'dragEnd',
                                    id: item.id,
                                    grouped: item.grouped,
                                    grouped_by: item.grouped_by,
                                    xpos: x,
                                    ypos: y,
                                    in_screen: isElementinScreen,
                                    type: 'designSystem'
                                };

                                if(isContainer && item.grouped_by !== isContainer.id) {
                                    editorContext.makeElementContainerChild(item, props, isContainer);
                                } else if(item.grouped && isContainer == false) {
                                    editorContext.removeElementContainerChild(item, props);
                                } else {
                                    editorContext.updateElement(props);
                                }
                            }}
                            onTransform={(e) => {
                                if(item.id != selectedId && selectedId != editorContext.activeElement?.id) {
                                    return;
                                }
                                
                                setWidth(calcWidthHeight(e.target.attrs.scaleX, e.target.attrs.width));
                                setHeight(calcWidthHeight(e.target.attrs.scaleY, e.target.attrs.height));
                            }}
                            onTransformEnd={(e) => {
                                if(item.id != selectedId && selectedId != editorContext.activeElement?.id) {
                                    return;
                                }

                                const scaleX = e.target.attrs.scaleX;
                                const scaleY = e.target.attrs.scaleY;
                                
                                const finalWidth = calcWidthHeight(scaleX, e.target.width());
                                const finalHeight = calcWidthHeight(scaleY, e.target.height());
                                
                                e.target.width(finalWidth);
                                e.target.height(finalHeight);

                                const props = {
                                    from: 'transformEnd',
                                    id: item.id,
                                    grouped: item.grouped,
                                    grouped_by: item.grouped_by,
                                    width: finalWidth,
                                    height: finalHeight,
                                    scaleX: 1,
                                    scaleY: 1,
                                    type: 'designSystem'
                                };
                                editorContext.updateElement(props);
                                e.target.setAttrs({scaleX: 1, scaleY: 1});
                            }}
                        />
                        {selectedId == editorContext.activeElement?.id && (
                            <ContextMenu 
                                contextMenuOptions={contextMenuOptions}
                                item={item}
                                isSelected={item.id === selectedId}
                                domensions={domensions}
                                xpos={xpos}
                                ypos={ypos}
                                width={width}
                                height={height}
                                stageRef={stageRef}
                            />
                        )}
                        {selectedId == editorContext.activeElement?.id && 
                        editorContext.activeElement?.prototyping && 
                        !editorContext?.isCanvasSelected && 
                        !editorContext?.aScreenIsActive && (
                            <PrototypeMenu item={item} xpos={xpos} ypos={ypos} width={width} height={height} stageRef={stageRef} />
                        )}
                    </>
                ) : null}
            </>
            {item.id == selectedId && selectedId == editorContext.activeElement?.id && (
                <>
                    {hLines.map((item, i) => (
                        <Line key={i} {...item} />
                    ))}
                    {vLines.map((item, i) => (
                        <Line key={i} {...item} />
                    ))}
                </>
            )}
        </Group>
    );
}

export default DesignSystem;