import React, {useEffect, useState} from 'react';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import '../../dropdown.css';
import './trigger.css';
import '../../prototypemenu.css';
import { useEditorContext } from '../../../../../context/editor/editorcontext';
import { textfieldProtoOptions, buttonProtoOptions, textProtoOptions, switchProtoOptions, imageProtoOptions, containerProtoOptions, designSystemProtoOptions } from '../../../../../context/editor/prototypeoptions/prototypeoptions';


// const getComponentCategory = (componentName) => {
//     console.log(componentName);
//     const lowercaseName = componentName.toLowerCase();
//     for (const [category, info] of Object.entries(designSystemCategories)) {
//         console.log(category, info);
//         if (info.components.includes(lowercaseName)) {
//             return category;
//         }
//     }
//     return null;
// };


function Trigger({item, currentInteraction, onUpdate}) {
    const editorContext = useEditorContext();
    const [trig, setTrig] = useState(currentInteraction?.trigger || 'Select Trigger');

    const renderOptions = (type) => {
        console.log(type);
        switch (type) {
            case 'textfield':
                return textfieldProtoOptions.trigger;
            case 'button':
                return buttonProtoOptions.trigger;
            case 'text':
                return textProtoOptions.trigger;
            case 'switch':
                return switchProtoOptions.trigger;
            case 'image':
                return imageProtoOptions.trigger;
            case 'container':
                return containerProtoOptions.trigger;
            case 'designSystem':
                return designSystemProtoOptions.trigger;
                // const category = getComponentCategory(item.component_name);
                // console.log(category);
                // return designSystemCategories[category].trigger
            default:
                break;
        }
    }

    const handle = (triggerType) => {
        setTrig(triggerType);
        onUpdate(triggerType);
    }

    return(
        <div className='triggerContainer'>
            <div>
                <h3 className='prototype-menu-title'>Trigger</h3>
            </div>
            <DropdownMenu.Root>
                <DropdownMenu.Trigger asChild>
                    <button className="prototype-menu-dropdown-trigger" aria-label="Customise options">
                        {trig}
                    </button>
                </DropdownMenu.Trigger>
                <DropdownMenu.Portal>
                    <DropdownMenu.Content className="prototype-menu-dropdown-content">
                        {renderOptions(editorContext.activeElement.type).map((e) => (
                            <DropdownMenu.Item 
                                key={e}
                                className="prototype-menu-dropdown-item" 
                                onClick={() => handle(`${e}`)}
                            >
                                {e}
                            </DropdownMenu.Item>
                        ))}                
                    </DropdownMenu.Content>
                </DropdownMenu.Portal>
            </DropdownMenu.Root>
        </div>
    )
}

export default Trigger;