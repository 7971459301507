import React, { useState, useEffect, useRef } from 'react';
import { Stage, Layer, Rect, Text as KonvaText, Circle, Group } from 'react-konva';
import { usePrototypeContext } from '../../context/prototype/prototypecontext';
import PlayerTextField from './elements/textfield/playertextfield'; 
import PlayerButton from './elements/button/playerbutton';
import PlayerText from './elements/text/playertext';
import PlayerSwitch from './elements/switch/playerswitch';
import PlayerImage from './elements/image/playerimage';
import PlayerContainer from './elements/container/playercontainer';
import PlayerDesignSystem from './elements/designsystem/playerdesignsystem';
import { RepositionElements } from './utils/repositionElements';
import { Spring, animated, useSpring } from '@react-spring/konva';
import PlayerHeader from './header/playerheader';
import { EditorContext } from '../../context/editor/editorcontext';
import PrototypeScreen from '../screens/prototypescreen';
import './playercanvas.css';


function PlayerCanvas ({setScreenX, setScreenY, setScreenScale,  setClipScreenWidth, setClipScreenHeight, clipTop, clipBottom }) {

    // render all elements
    // render home screen first (screen[0] for meantime)
    // render home screen and element on canvas

    const prototypeContext = usePrototypeContext();
    const [dimensions, setDimensions] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    });
    const canvasWidth = dimensions.width;
    const canvasHeight = dimensions.height;
    const stageRef = useRef();
    const [screenWidth, setScreenWidth] = useState(null);
    const [screenHeight, setScreenHeight] = useState(null);
    const [scale, setScale] = useState(0.8);
    const [readyToRender, setReadyToRender] = useState(false);
    const [CalcTranslation, setCalcTranslation] = useState(false);
    const [stageY, setStageY] = useState(0);


    const newCanvasWidth = canvasWidth * (10 / (scale * 10)) -20;
    const newCanvasHeight = canvasHeight * (10 / (scale * 10)) -20;
    const x = newCanvasWidth/2;
    const y = newCanvasHeight/2;

    // Add this useEffect for resize handling
    useEffect(() => {
        const handleResize = () => {
            setDimensions({
                width: window.innerWidth,
                height: window.innerHeight
            });
        };
    
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(()=>{
        //   console.log('about to load prototype', screenWidth, prototypeContext.screens)
        // if(CalcTranslation && prototypeContext.screens){
        //     console.log('screen width', screenWidth)
        //     if(screenWidth > canvasWidth){
        //         console.log('screen is wider than tall');
        //         const newdimsW = screenWidth - (screenWidth - canvasWidth) - 150;
        //         setScale(newdimsW / screenWidth);
        //         setScreenScale(newdimsW / screenWidth);
        //     }else{
        //         console.log(' screen is taller than wide');
        //         const newdimsH = screenHeight - (screenHeight - canvasHeight) - 160;
        //         setScale(newdimsH / screenHeight);
        //         setScreenScale(newdimsH / screenHeight);
        //     }
        //     setReadyToRender(true);
        // }   
        
        if (CalcTranslation && prototypeContext.screens) {
            // Define header height and desired padding
            const HEADER_HEIGHT = 64; // Adjust based on your actual header height
            const PADDING = 40; // Padding on all sides
            
            // Available space accounting for header and padding
            const availableWidth = canvasWidth - (PADDING * 2);
            const availableHeight = canvasHeight - HEADER_HEIGHT - (PADDING * 2);
            
            // Calculate scale ratios for both dimensions
            const widthRatio = availableWidth / screenWidth;
            const heightRatio = availableHeight / screenHeight;
            
            // Use the smaller ratio to ensure screen fits in both dimensions
            const finalScale = Math.min(widthRatio, heightRatio);
            setScale(finalScale);
            setScreenScale(finalScale);
            setReadyToRender(true);
        }
    },[prototypeContext.screens, CalcTranslation])

    useEffect(()=>{
        if(prototypeContext.file){
            // get screen size
            const fileScreenSize = prototypeContext?.file?.screen_size;
            const screenSize = prototypeContext.getScreenSize(fileScreenSize);
            prototypeContext.getScreens(prototypeContext.file.id);


            console.log('this is the screens', prototypeContext.screens)
            setScreenWidth(screenSize.width);
            setScreenHeight(screenSize.height);
            setClipScreenWidth(screenSize.width);
            setClipScreenHeight(screenSize.height);
            setCalcTranslation(true);

            //setting for parent to calculate clipping mask
            //console.log('setting screenY', y - screenHeight/2)
            setScreenX((x  - (screenWidth/2)) * scale);
            setScreenY((y  - (screenHeight/2)) * scale);
        }
    },[prototypeContext.file])

    useEffect(() => {
        if (prototypeContext.currentRenderedScreen) {
            // Reset stageY when transitioning to a non-scrollable screen
            if (!prototypeContext.currentRenderedScreen.scroll_height || 
                prototypeContext.currentRenderedScreen.scroll_height <= prototypeContext.currentRenderedScreen.height) {
                setStageY(0);
            }
        }
    }, [prototypeContext.currentRenderedScreen]);

    // Add effect to reset stage position when screen changes
    useEffect(() => {
        if (stageRef.current && prototypeContext.currentRenderedScreen) {
            stageRef.current.y(0);  // Reset to top of screen
            
            // Update clip dimensions for new screen
            setClipScreenWidth(prototypeContext.currentRenderedScreen.width);
            setClipScreenHeight(prototypeContext.currentRenderedScreen.height);
        }
    }, [prototypeContext.currentRenderedScreen]);

    useEffect(() => {
        const handleWheel = (e) => {
            // Only handle wheel events when over the canvas
            const canvasElement = stageRef.current?.container();
            if (!canvasElement) return;

            const rect = canvasElement.getBoundingClientRect();
            if (e.clientX >= rect.left && 
                e.clientX <= rect.right && 
                e.clientY >= rect.top && 
                e.clientY <= rect.bottom) {
                
                // Let horizontal scroll pass through to design system components
                if (Math.abs(e.deltaX) > Math.abs(e.deltaY)) {
                    return;
                }
                
                e.preventDefault();
                
                const currentScreen = prototypeContext.currentRenderedScreen;
                if (!currentScreen?.scroll_height || currentScreen.scroll_height <= currentScreen.height) return;

                const dy = e.deltaY;
                const clipTopPos = clipTop / scale;
                const clipBottomPos = clipBottom / scale;
                const visibleHeight = clipBottomPos - clipTopPos;
                
                let newY = (stageRef.current?.y() || 0) - dy;
                
                if (newY > 0) newY = 0;
                
                const maxScrollUp = -(currentScreen.scroll_height - visibleHeight) * scale;
                if (newY < maxScrollUp) newY = maxScrollUp;
                
                if (stageRef.current) {
                    stageRef.current.y(newY);
                    stageRef.current.batchDraw();
                }
            }
        };

        document.addEventListener('wheel', handleWheel, { passive: false });
        return () => document.removeEventListener('wheel', handleWheel);
    }, [scale, clipTop, clipBottom, prototypeContext.currentRenderedScreen]);

    // Render element function inside the component
    const renderElement = (item) => {
        if(item.grouped || item.in_screen==false) return;
        switch (item.type) {
            case 'textfield':
                return <PlayerTextField element={item} screenWidth={screenWidth} screenHeight={screenHeight} canvasX={x} canvasY={y} scale={scale}/>
            case 'button':
                return <PlayerButton element={item} screenWidth={screenWidth} screenHeight={screenHeight} canvasX={x} canvasY={y} scale={scale}/>
            case 'text':
                return <PlayerText element={item} screenWidth={screenWidth} screenHeight={screenHeight} canvasX={x} canvasY={y} scale={scale}/>
            case 'switch':
                return <PlayerSwitch element={item} screenWidth={screenWidth} screenHeight={screenHeight} canvasX={x} canvasY={y} scale={scale}/>
            case 'image':
                return <PlayerImage element={item} screenWidth={screenWidth} screenHeight={screenHeight} canvasX={x} canvasY={y} scale={scale}/>
            case 'container':
                return <PlayerContainer element={item} screenWidth={screenWidth} screenHeight={screenHeight} canvasX={x} canvasY={y} scale={scale}/>
            case 'designSystem':
                return <PlayerDesignSystem element={item} screenWidth={screenWidth} screenHeight={screenHeight} canvasX={x} canvasY={y} scale={scale} />
            default:
                return null;
        }
    }

    return(
        <Stage 
            name='playercanvas'
            ref={stageRef}
            width={canvasWidth} 
            height={canvasHeight} 
            perfectDrawEnabled={false}
            scaleX={scale}
            scaleY={scale}
            style={{zIndex: 1}}
        >   
            <Layer>
                <PrototypeScreen x={x} y={y} offsetX={screenWidth/2} offsetY={screenHeight/2} width={screenWidth}/>
                {readyToRender && 
                    prototypeContext.element.toReversed().map((item) => renderElement(item))
                }
            </Layer>
        </Stage>
    );
}

export default PlayerCanvas;