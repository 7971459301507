import React, { useState, useEffect, useRef, Fragment } from 'react';
import * as Dialog from "@radix-ui/react-dialog";
import { SizeIcon } from '@radix-ui/react-icons';
import { LiveProvider, LiveEditor, LiveError, LivePreview } from 'react-live';

import { Link, useNavigate} from 'react-router-dom';
import './expandedcodeview.css';
import { useEditorContext } from '../../../context/editor/editorcontext';

const MemoizedLiveEditor = React.memo(LiveEditor);

function ExpandedCodeView({item, code, scope, theme, language}){

    const [selection, setSelection] = useState(null);
    const editorContext = useEditorContext();
    const navigate = useNavigate();
    const [localCode, setLocalCode] = useState(code);
    const [zoom, setZoom] = useState(1);


    const handleCodeChange = (newCode) => {     
        setLocalCode(newCode);  // Update local state immediately
    };

    // Add this to update context when dialog closes
    const handleDialogClose = () => {
        editorContext.updateElementCustomPanel({
            id: item.id,
            type: item.type,
            grouped: item.grouped,
            grouped_by: item.grouped_by,
            code: localCode
        });
    };

    return(
    <Dialog.Root onOpenChange={(open) => !open && handleDialogClose()}>
		<Dialog.Trigger asChild>
			<button className="expandedCodeViewButton violet">
                <SizeIcon />
            </button>
		</Dialog.Trigger>
		<Dialog.Portal>
			<Dialog.Overlay className="expandedCodeViewOverlay" />
			<Dialog.Content className="expandedCodeViewContent">
                <div className="expandedCodeViewEditorPanel">
                    <div className="expandedCodeViewEditorPanelHeader">
                        <p>Code</p>
                    </div>
                    <LiveProvider 
                        code={localCode}  // Use local state instead of item.code
                        scope={scope}
                        theme={theme}
                        language={language}
                    >
                    <div className="expandedCodeViewEditorPanelContent" style={{
                        position: 'relative',
                        marginBottom: '16px',
                    }}>
                        <LiveEditor 
                            onChange={handleCodeChange}
                            style={{
                                fontSize: '14px',
                                fontFamily: 'ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, monospace',
                                backgroundColor: '#292D3E',
                                lineHeight: '1.5',
                                height: '100%',
                                width: '100%',
                                boxSizing: 'border-box',
                                paddingBottom: '30px',
                            }}
                            padding={10}
                            showLineNumbers={true}
                            // Disable features that might cause performance issues
                            disabled={false}
                            language={language}
                        />
                    </div>
                    <LiveError 
                        style={{
                            color: '#ff5555',
                            margin: '8px 0',
                            padding: '12px',
                            backgroundColor: 'rgba(255,85,85,0.1)',
                            borderRadius: '6px',
                            fontSize: '13px',
                            fontFamily: 'ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, monospace',
                        }}
                    />
                    </LiveProvider>
                </div>
                <div className="expandedCodeViewPreviewPanel">
                    <div className="expandedCodeViewPreviewPanelHeader">
                        <p>Preview</p>
                    </div>
                    <div className="expandedCodeViewPreviewPanelContent">

                        <div className="previewContainer">
                            <div style={{
                                width: item.width,
                                height: item.height,
                                transform: `scale(${Math.min(1, 
                                    Math.min(
                                        (300 / item.width), // assuming 400px is preview width
                                        (400 / item.height) // assuming 400px is preview height
                                    )
                                )})`,
                                transformOrigin: 'center',
                            }}>
                                <LiveProvider 
                                    code={localCode} 
                                    scope={scope}
                                >
                                    <LivePreview />
                                </LiveProvider>
                            </div>
                        </div>
                    </div>
                    
                </div>

			</Dialog.Content>
		</Dialog.Portal>
	</Dialog.Root>
    )
}

export default ExpandedCodeView;