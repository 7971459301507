import React, { useState, useEffect, useRef } from 'react';
import { Stage, Layer, Rect, Text as KonvaText, Circle } from 'react-konva';
import { useEditorContext } from '../../context/editor/editorcontext';
import Screen from '../screens/screen';
import TextField from '../elements/elements/textfield/textfield';
import Button from '../elements/elements/button/button';
import Text from '../elements/elements/text/text';
import Image from '../elements/elements/image/image';
import Switch from '../elements/elements/switch/switch';
import Container from '../elements/elements/container/container';
import DesignSystem from '../elements/designsystem/designsystem';
import PrototypeMenu from '../ui/prototypemenu/prototypemenu';


function Canvas({fileId}){

    const editorContext = useEditorContext();
    const canvasWidth = window.innerWidth;
    const canvasHeight = window.innerHeight;
    const stageRef = useRef(null);
    const scale = 0.5;
    let lastCenter = null;
    let lastDist = 0;
    const scaleBy = 1.1;

    function getDistance(p1, p2) {
    return Math.sqrt(Math.pow(p2.x - p1.x, 2) + Math.pow(p2.y - p1.y, 2));
    }

    function getCenter(p1, p2) {
    return {
        x: (p1.x + p2.x) / 2,
        y: (p1.y + p2.y) / 2,
    };
    }

    function isTouchEnabled() { 
    return ( 'ontouchstart' in window ) ||  
            ( navigator.maxTouchPoints > 0 ) ||  
            ( navigator.msMaxTouchPoints > 0 ); 
    } 

    function zoomStage(event) {
        event.evt.preventDefault();
        if (stageRef.current !== null) {
          const stage = stageRef.current;
          const oldScale = stage.scaleX();
          const { x: pointerX, y: pointerY } = stage.getPointerPosition();
          const mousePointTo = {
            x: (pointerX - stage.x()) / oldScale,
            y: (pointerY - stage.y()) / oldScale,
          };
          const newScale = event.evt.deltaY > 0 ? oldScale * scaleBy : oldScale / scaleBy;
          stage.scale({ x: newScale, y: newScale });
          const newPos = {
            x: pointerX - mousePointTo.x * newScale,
            y: pointerY - mousePointTo.y * newScale,
          }
          stage.position(newPos);
          stage.batchDraw();
        }
      }
    
      function handleTouch(e) {
        e.evt.preventDefault();
        var touch1 = e.evt.touches[0];
        var touch2 = e.evt.touches[1];
        const stage = stageRef.current;
        if (stage !== null) {
          if (touch1 && touch2) {
            if (stage.isDragging()) {
              stage.stopDrag();
            }
      
            var p1 = {
              x: touch1.clientX,
              y: touch1.clientY
            };
            var p2 = {
              x: touch2.clientX,
              y: touch2.clientY
            };
      
            if (!lastCenter) {
              lastCenter = getCenter(p1, p2);
              return;
            }
            var newCenter = getCenter(p1, p2);
      
            var dist = getDistance(p1, p2);
      
            if (!lastDist) {
              lastDist = dist;
            }
      
            // local coordinates of center point
            var pointTo = {
              x: (newCenter.x - stage.x()) / stage.scaleX(),
              y: (newCenter.y - stage.y()) / stage.scaleX()
            };
      
            var scale = stage.scaleX() * (dist / lastDist);
      
            stage.scaleX(scale);
            stage.scaleY(scale);
      
            // calculate new position of the stage
            var dx = newCenter.x - lastCenter.x;
            var dy = newCenter.y - lastCenter.y;
      
            var newPos = {
              x: newCenter.x - pointTo.x * scale + dx,
              y: newCenter.y - pointTo.y * scale + dy
            };
      
            stage.position(newPos);
            stage.batchDraw();
      
            lastDist = dist;
            lastCenter = newCenter;
          }
        }
      }
    
      function handleTouchEnd() {
        lastCenter = null;
        lastDist = 0;
      }

      function handleOnClick(e){
        // deactivate all screens
        // check click event is on empty part of the canvas
        const emptySpace = e.target === e.target.getStage();
        if (!emptySpace) {
          //console.log('not empty')
          editorContext.setCanvasIsSelected(false);
          return;
        }
        //console.log('empty')
        //clicked on empty stage
        editorContext.setCanvasIsSelected(true);
        // call screen change state function
        editorContext.changeScreenActiveState(stageRef.current);
      }

    return(
        <Stage 
            name='editorcanvas'
            width={canvasWidth} 
            height={canvasHeight} 
            draggable={!isTouchEnabled()}
            onWheel={zoomStage}
            onClick={handleOnClick}
            onTouchMove={handleTouch}
            onTouchEnd={handleTouchEnd}
            perfectDrawEnabled={false}
            scaleX={scale}
            scaleY={scale}
            ref={stageRef}
            style={{background: '#212226', zIndex:1}}
        >

          
            <Screen stageRef={stageRef}/>
            <Layer>
            {
              editorContext.loaded ? editorContext.element.toReversed().map((item) => {
                  if(item.grouped){return;}
                  else{
                    switch (item.type) {
                      case 'textfield':
                          return <TextField item={item} stageRef={stageRef.current} scale={scale}/>
                        break;
                      case 'button':
                          return <Button item={item} stageRef={stageRef.current} scale={scale}/>
                        break;
                      case 'text':
                          return <Text item={item} stageRef={stageRef.current} scale={scale}/>
                        break;
                      case 'image':
                          return <Image item={item} stageRef={stageRef.current} fileId={fileId} scale={scale}/>
                        break;
                      case 'switch':
                          return <Switch item={item} stageRef={stageRef.current} scale={scale}/>
                        break;
                      case 'container':
                        return <Container item={item} stageRef={stageRef.current} fileId={fileId} scale={scale}/>
                        break;
                      case 'designSystem':
                        return <DesignSystem item={item} stageRef={stageRef.current} scale={scale}/>
                        break;
                      default:
                        break;
                    }
                  }
                  
              }):null
            }
             </Layer> 
        </Stage>
    );
}

export default Canvas;