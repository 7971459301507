// Check if rect r2 has any overlap with r1
// Each rect should be of form {left: , top: , right: , bottom}
export function rectInRect(r1, r2) {
    // Use r2's values directly since they're already calculated
    const elementRect = {
        left: r2.left,
        top: r2.top,
        right: r2.right,    // Use provided right instead of calculating
        bottom: r2.bottom   // Use provided bottom instead of calculating
    };

    // Check if there's NO overlap at all (component is completely outside)
    return !(elementRect.left >= r1.right || 
             elementRect.right <= r1.left || 
             elementRect.top >= r1.bottom ||
             elementRect.bottom <= r1.top);
}

// Check point is inside rect
export function ptInRect(pt, rect){
    if ( (pt.x >= rect.left) && (pt.x <= rect.right) && (pt.y >= rect.top) && (pt.y <= rect.bottom)){ 
    return true;
  } 
  return false;
}