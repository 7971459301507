import { muiScope, muiComponents } from './mui/components';
// import { chakraScope, chakraComponents } from './chakra/components';
import { antScope, antComponents } from './antd/components';

// Function to get the appropriate scope based on design system
export const getDesignSystemScope = (systemId) => {
    switch (systemId) {
        case 'mui':
            return muiScope;
        case 'antd':
            return antScope;
        default:
            return muiScope; // fallback to MUI
    }
};

// Export combined components
export const designSystemComponents = {
    mui: muiComponents,
    antd: antComponents
};

// Export all scopes
export const designSystemScope = {
    ...muiScope, // Keep MUI as default for backward compatibility
    Rivveo: {
        triggerPrototype: () => {} // Empty function for editor
    }
};

export const getSystemComponents = (systemId) => {
    return designSystemComponents[systemId]?.components || [];
};

export const getAvailableDesignSystems = () => {
    return Object.entries(designSystemComponents).map(([id, system]) => ({
        id,
        name: system.name
    }));
};

export const getComponent = (systemId, componentName) => {
    const system = designSystemComponents[systemId];
    if (!system) {
        console.error(`Design system ${systemId} not found`);
        return null;
    }

    return system.components.find(c => c.name === componentName) || null;
};