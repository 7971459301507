import { useState, useEffect } from 'react';

export const useMeasurementTool = (stageRef, isSelected, elementProps) => {
    const [measurementLine, setMeasurementLine] = useState(null);

    const getAbsolutePosition = (element) => {
        console.log('getAbsolutePosition called with:', element);
        return element;
        // if (!element.grouped) {
        //     console.log('Element not grouped, returning:', element);
        //     return element;
        // }

        // // If element is in a container, we need to find the container's position
        // const stage = stageRef.getStage();
        // console.log('Stage:', stage);
        
        // const container = stage.findOne(`#${element.grouped_by}`);
        // console.log('Found container:', container);
        
        // if (!container) {
        //     console.log('No container found, returning:', element);
        //     return element;
        // }
        
        // console.log('element', element);
        // console.log('container', container);

        // // Log the positions to debug
        // const result = {
        //     ...element,
        //     x: element.x,
        //     y: element.y,
        //     width: element.width,
        //     height: element.height,
        //     grouped: element.grouped,
        //     grouped_by: element.grouped_by
        // };
        // console.log('Returning transformed position:', result);
        // return result;
    };

    const findClosestPoints = (source, target, isTargetScreen) => {
        // Transform positions only if needed
        const sourcePos = getAbsolutePosition(source, stageRef);
        const targetPos = isTargetScreen ? target : getAbsolutePosition(target, stageRef);
        console.log('sourcePos', sourcePos);
        console.log('targetPos', targetPos);

        if(source.grouped && target.grouped && source.grouped_by === target.grouped_by) {
            // If both components are in the same container, skip container logic
            return;
        }

        if(source.grouped) {
            console.log('source is grouped', source, target);

            const stage = stageRef.getStage();            
            const container = stage.findOne(`#${source.grouped_by}`);
            const sourceX = source.x;
            const sourceY = source.y;
            const actualX = container.attrs.x + sourceX;
            const actualY = container.attrs.y + sourceY;

            // Convert target coordinates to be relative to container
            const targetRelativeX = target.x - container.attrs.x;
            const targetRelativeY = target.y - container.attrs.y;

            // Determine which edge to measure against based on absolute positions
            const sourceCenter = {
                x: actualX + source.width/2,
                y: actualY + source.height/2
            };
            const targetCenter = {
                x: target.x + target.width/2,
                y: target.y + target.height/2
            };

            // Calculate vertical and horizontal distances
            const verticalDist = Math.abs(sourceCenter.y - targetCenter.y);
            const horizontalDist = Math.abs(sourceCenter.x - targetCenter.x);

            // If vertical distance is greater than horizontal distance, use vertical measurements
            if (verticalDist > horizontalDist) {
                if (sourceCenter.y < targetCenter.y) {
                    return {
                        from: 'top',
                        start: { x: sourceX + source.width/2, y: sourceY + source.height },
                        end: { x: sourceX + source.width/2, y: targetRelativeY },
                        distance: Math.abs(actualY + source.height - target.y)
                    };
                } else {
                    return {
                        from: 'bottom',
                        start: { x: sourceX + source.width/2, y: sourceY },
                        end: { x: sourceX + source.width/2, y: targetRelativeY + target.height },
                        distance: Math.abs(actualY - (target.y + target.height))
                    };
                }
            } 
            // Otherwise use horizontal measurements
            else {
                if (sourceCenter.x < target.x) {
                    return {
                        from: 'left',
                        start: { x: sourceX + source.width, y: sourceY + source.height/2 },
                        end: { x: targetRelativeX, y: sourceY + source.height/2 },
                        distance: Math.abs(actualX + source.width - target.x)
                    };
                } else {
                    return {
                        from: 'right',
                        start: { x: sourceX, y: sourceY + source.height/2 },
                        end: { x: targetRelativeX + target.width, y: sourceY + source.height/2 },
                        distance: Math.abs(actualX - (target.x + target.width))
                    };
                }
            }
        }

        if (isTargetScreen) {
            // Check if component is inside screen bounds
            const isInside = 
                sourcePos.x >= targetPos.x && 
                sourcePos.x + sourcePos.width <= targetPos.x + targetPos.width &&
                sourcePos.y >= targetPos.y && 
                sourcePos.y + sourcePos.height <= targetPos.y + targetPos.height;

            if (isInside) {
                return {
                    isScreen: true,
                    source: sourcePos,
                    top: {
                        start: { x: sourcePos.x + sourcePos.width/2, y: sourcePos.y },
                        end: { x: sourcePos.x + sourcePos.width/2, y: targetPos.y },
                        distance: Math.abs(sourcePos.y - targetPos.y)
                    },
                    bottom: {
                        start: { x: sourcePos.x + sourcePos.width/2, y: sourcePos.y + sourcePos.height },
                        end: { x: sourcePos.x + sourcePos.width/2, y: targetPos.y + targetPos.height },
                        distance: Math.abs((sourcePos.y + sourcePos.height) - (targetPos.y + targetPos.height))
                    },
                    left: {
                        start: { x: sourcePos.x, y: sourcePos.y + sourcePos.height/2 },
                        end: { x: targetPos.x, y: sourcePos.y + sourcePos.height/2 },
                        distance: Math.abs(sourcePos.x - targetPos.x)
                    },
                    right: {
                        start: { x: sourcePos.x + sourcePos.width, y: sourcePos.y + sourcePos.height/2 },
                        end: { x: targetPos.x + targetPos.width, y: sourcePos.y + sourcePos.height/2 },
                        distance: Math.abs((sourcePos.x + sourcePos.width) - (targetPos.x + targetPos.width))
                    }
                };
            } else {
                // Determine which edge to measure against based on position
                const sourceCenter = {
                    x: sourcePos.x + sourcePos.width/2,
                    y: sourcePos.y + sourcePos.height/2
                };
                const targetCenter = {
                    x: targetPos.x + targetPos.width/2,
                    y: targetPos.y + targetPos.height/2
                };
                
                // If component is to the left of the screen
                if (sourceCenter.x < targetPos.x) {
                    return {
                        source: sourcePos,
                        start: { x: sourcePos.x + sourcePos.width, y: targetCenter.y },
                        end: { x: targetPos.x, y: targetCenter.y },
                        distance: Math.abs(sourcePos.x + sourcePos.width - targetPos.x)
                    };
                }
                // If component is to the right of the screen
                else if (sourceCenter.x > targetPos.x + targetPos.width) {
                    return {
                        source: sourcePos,
                        start: { x: sourcePos.x, y: sourcePos.y + sourcePos.height/2 },
                        end: { x: targetPos.x + targetPos.width, y: sourcePos.y + sourcePos.height/2 },
                        distance: Math.abs((sourcePos.x + sourcePos.width) - (targetPos.x + targetPos.width))
                    };
                }
                // If component is above the screen
                else if (sourceCenter.y < targetPos.y) {
                    return {
                        source: sourcePos,
                        start: { x: sourcePos.x + sourcePos.width/2, y: sourcePos.y },
                        end: { x: sourcePos.x + sourcePos.width/2, y: targetPos.y },
                        distance: Math.abs(sourcePos.y - targetPos.y)
                    };
                }
                // If component is below the screen
                else {
                    return {
                        source: sourcePos,
                        start: { x: sourcePos.x + sourcePos.width/2, y: sourcePos.y },
                        end: { x: sourcePos.x + sourcePos.width/2, y: targetPos.y + targetPos.height },
                        distance: Math.abs((sourcePos.y + sourcePos.height) - (targetPos.y + targetPos.height))
                    };
                }
            }
        }

        // For component to component measurement
        const sourcePoints = [
            { x: sourcePos.x, y: sourcePos.y }, // top-left
            { x: sourcePos.x + sourcePos.width, y: sourcePos.y }, // top-right
            { x: sourcePos.x, y: sourcePos.y + sourcePos.height }, // bottom-left
            { x: sourcePos.x + sourcePos.width, y: sourcePos.y + sourcePos.height }, // bottom-right
            { x: sourcePos.x + sourcePos.width/2, y: sourcePos.y }, // top-middle
            { x: sourcePos.x + sourcePos.width/2, y: sourcePos.y + sourcePos.height }, // bottom-middle
            { x: sourcePos.x, y: sourcePos.y + sourcePos.height/2 }, // left-middle
            { x: sourcePos.x + sourcePos.width, y: sourcePos.y + sourcePos.height/2 } // right-middle
        ];

        const targetPoints = [
            { x: targetPos.x, y: targetPos.y }, // top-left
            { x: targetPos.x + targetPos.width, y: targetPos.y }, // top-right
            { x: targetPos.x, y: targetPos.y + targetPos.height }, // bottom-left
            { x: targetPos.x + targetPos.width, y: targetPos.y + targetPos.height }, // bottom-right
            { x: targetPos.x + targetPos.width/2, y: targetPos.y }, // top-middle
            { x: targetPos.x + targetPos.width/2, y: targetPos.y + targetPos.height }, // bottom-middle
            { x: targetPos.x, y: targetPos.y + targetPos.height/2 }, // left-middle
            { x: targetPos.x + targetPos.width, y: targetPos.y + targetPos.height/2 } // right-middle
        ];

        let minDistance = Infinity;
        let closestStart = null;
        let closestEnd = null;

        sourcePoints.forEach(sp => {
            targetPoints.forEach(tp => {
                const dx = tp.x - sp.x;
                const dy = tp.y - sp.y;
                const distance = Math.sqrt(dx * dx + dy * dy);

                if (distance < minDistance) {
                    minDistance = distance;
                    closestStart = sp;
                    closestEnd = tp;
                }
            });
        });

        return {
            source: sourcePos,
            start: closestStart,
            end: closestEnd,
            distance: minDistance
        };
    };

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.altKey) {
                stageRef.container().style.cursor = 'crosshair';
                // If it's an arrow key, trigger the measurement update
                if (event.key.startsWith('Arrow')) {
                    const stage = stageRef.getStage();
                    const point = stage.getPointerPosition();
                    handleMouseMove({ altKey: true });
                }
            }
        };

        const handleKeyUp = (event) => {
            if (!event.altKey) {
                stageRef.container().style.cursor = 'default';
                setMeasurementLine(null);
            }
        };

        const handleMouseMove = (event) => {
            if (!event.altKey || !isSelected) return;

            // Just add logging here to see what we have
            if (elementProps.grouped) {
                console.log('Container info:', {
                    grouped: elementProps.grouped,
                    grouped_by: elementProps.grouped_by,
                    elementPos: {
                        x: elementProps.xpos,
                        y: elementProps.ypos
                    }
                });
            }

            const stage = stageRef.getStage();
            const point = stage.getPointerPosition();
            
            const targetElement = stage.getIntersection(point);
            //.log('Mouse move - target element:', targetElement?.parent?.attrs);

            if (!targetElement || !targetElement.parent) {
                setMeasurementLine(null);
                return;
            }

            const targetRect = targetElement.parent;
            
            // Check if we're measuring against the same element
            if (targetRect.attrs.x === elementProps.xpos && 
                targetRect.attrs.y === elementProps.ypos) {
                return;
            }

            const sourceElement = {
                x: elementProps.xpos,
                y: elementProps.ypos,
                width: elementProps.width,
                height: elementProps.height,
                grouped: elementProps.grouped,
                grouped_by: elementProps.grouped_by
            };

            // Check if the target is a screen
            const isScreen = targetRect.name() === 'screen';
            const targetElementRect = {
                x: isScreen ? targetRect.findOne('Rect').attrs.x : targetRect.attrs.x,
                y: isScreen ? targetRect.findOne('Rect').attrs.y : targetRect.attrs.y,
                width: isScreen ? targetRect.findOne('Rect').attrs.width : targetRect.attrs.width,
                height: isScreen ? targetRect.findOne('Rect').attrs.height : targetRect.attrs.height,

            };
            console.log('Target Element Rect:', targetElementRect);
            console.log('Source Element:', sourceElement);
            const measurement = findClosestPoints(sourceElement, targetElementRect, isScreen);
            console.log('Measurement:', measurement);
            setMeasurementLine(measurement);
        };

        window.addEventListener('keydown', handleKeyDown);
        window.addEventListener('keyup', handleKeyUp);
        if (stageRef.container()) {
            stageRef.container().addEventListener('mousemove', handleMouseMove);
        }

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
            window.removeEventListener('keyup', handleKeyUp);
            if (stageRef.container()) {
                stageRef.container().removeEventListener('mousemove', handleMouseMove);
            }
        };
    }, [isSelected, elementProps.xpos, elementProps.ypos, elementProps.width, elementProps.height]);

    return measurementLine;
};
