import React, {useRef, useEffect, useLayoutEffect, useState, useMemo} from "react";
import { Html } from 'react-konva-utils';
import { Stage, Layer, Rect, Text as KonvaText, Circle, Group, Transformer } from 'react-konva';
import { useMeasure } from "react-use";
import { Portal } from 'react-konva-utils';
import { useEditorContext } from "../../../../../context/editor/editorcontext";
import { calcWidthHeight } from "../../../../../utils/editor/calcWidthHeight";
import { Item } from "@radix-ui/react-dropdown-menu";
import MeasurementLine from '../../../utils/measurementline';
import { useMeasurementTool } from '../../../utils/useMeasurementTool';

//child elements
import TextField from "../../textfield/textfield";
import Button from "../../button/button";
import Text from "../../text/text";
import Image from "../../image/image";
import Switch from "../../switch/switch";
import Container from "../container";
import DesignSystem from "../../../designsystem/designsystem";

// Create a memoized child component wrapper
const ContainerChild = React.memo(({ child, stageRef, fileId}) => {
    if(!child.grouped)return;
    switch (child.type) {
      case 'textfield':
        return <TextField item={child} stageRef={stageRef}/>;
      case 'button':
        return <Button item={child} stageRef={stageRef}/>;
      case 'text':
        return <Text item={child} stageRef={stageRef} />;
      case 'image':
        return <Image item={child} stageRef={stageRef} fileId={fileId}/>;
      case 'switch':
        return <Switch item={child} stageRef={stageRef}/>;
      case 'container':
        return <Container item={child} stageRef={stageRef} />;
      case 'designSystem':
        return <DesignSystem item={child} stageRef={stageRef}/>
      default:
        return null;
    }
  }, (prevProps, nextProps) => {
    // Custom comparison for the memo
    return JSON.stringify(prevProps.child) === JSON.stringify(nextProps.child);
  });


function CoreContainer({scale, stageRef, keyVal, shapeProps, setDomensions, isSelected, setSelectedId, elWidth, elHeight,  onSelect, onDragMove, onDragEnd, onTransform, onTransformEnd, fileId}) {

    const shapeRef = useRef();
    const parentRef = useRef();
    const trRef = useRef();

    const editorContext = useEditorContext();

    const [domDimensions, setDomDimensions] = useState({width:50, height:50})
    const [dimensionRef, { x, y, width, height, top, right, bottom, left }] = useMeasure();

    const [loaded, setLoaded] = useState(false);
    const [loadedWidth, setLoadedWidth] = useState(null);
    const [loadedHeight, setLoadedHeight] = useState(null);

    const [interactionMode, setInteractionMode] = useState(false);
    const [hoverState, setHoverState] = useState(false);

    const measurementLine = useMeasurementTool(stageRef, isSelected, {
        xpos: shapeProps.xpos,
        ypos: shapeProps.ypos,
        width: elWidth,
        height: elHeight,
        grouped: shapeProps.grouped,
        grouped_by: shapeProps.grouped_by
    });

    useEffect(()=>{
        setDomDimensions({
            width: width,
            height: height
        });
        setDomensions({
            width: width,
            height: height
        })
    },[width, height])
  
    useEffect(() => {
      if (isSelected) {
        trRef.current.nodes([shapeRef.current]);
        trRef.current.getLayer().batchDraw();
      }
    }, [isSelected]);
    
    useEffect(()=>{
        if(loaded == false){
            setLoadedWidth(shapeProps.width);
            setLoadedHeight(shapeProps.height);
            setLoaded(true);
        }
    },[])

    useEffect(()=>{
        if(editorContext.activeElement?.id == shapeProps.id){
            //console.log('triggering element', shapeProps.show_value )
            setSelectedId(shapeProps.id);
            setInteractionMode(editorContext.activeElement.interaction_mode)
        }
    },[editorContext.activeElement]);

    const deleteElement = (e) => {
        console.log('Key pressed', e)
    }

    useEffect(() => {
        const handleKeyDown = (event) => {
            if(isSelected){
                // console.log('keys', key)
                if ((event.metaKey || event.ctrlKey) && event.code === 'Backspace') {
                    const props = {
                        id: shapeProps.id,
                        type: shapeProps.type,
                        grouped: shapeProps.grouped,
                        grouped_by: shapeProps.grouped_by,
                    }
                    editorContext.deleteElement(props);
                  }              
            }
        }
        //add a global event for `keydown`
        window.addEventListener('keydown', handleKeyDown)
       //clean up after component unmounted
       return () => window.removeEventListener('keydown', handleKeyDown)
     }, [isSelected])

     React.useEffect(() => {
        if(!isSelected) return
        const konvaContent = stageRef.content;
        const transformerElement = trRef.current.getNode().getCanvas()._canvas;
        
        transformerElement.style.position = 'absolute';
        transformerElement.style.top = '0';
        transformerElement.style.left = '0';
        transformerElement.style.zIndex = '9999';
        transformerElement.style.pointerEvents = 'none';

        
        konvaContent.appendChild(transformerElement);
    }, [isSelected]);



     // Memoize the children array
  const containerChildren = useMemo(() => {
    return shapeProps.children
      .slice() // Create a new array instead of mutating with reverse()
      .reverse()
      .map((child) => (
        <ContainerChild
          key={child.id}
          child={child}
          stageRef={stageRef}
          fileId={fileId}
        />
      ));
  }, [shapeProps.children, stageRef, fileId]);

    return(
        <>
            <Group 
                key={keyVal}
                draggable={true} 
                onDragMove={onDragMove} 
                onDragEnd={onDragEnd} 
                x={shapeProps.xpos}
                y={shapeProps.ypos}
                width={parseFloat(elWidth)}
                height={parseFloat(elHeight)}
                name="container"
                id={shapeProps.id}
            >
                <Html divProps={{ style: { pointerEvents: interactionMode && isSelected ? "auto" : "none",}}}>
                    <div ref={dimensionRef} style={{
                        width: parseFloat(elWidth), 
                        height: parseFloat(elHeight), 
                        fontFamily: shapeProps.font, 
                        fontSize: parseInt(shapeProps.font_size),
                        color: shapeProps.font_colour,
                        borderTopLeftRadius: shapeProps.border_radius_tl,
                        borderTopRightRadius: shapeProps.border_radius_tr,
                        borderBottomLeftRadius: shapeProps.border_radius_bl,
                        borderBottomRightRadius: shapeProps.border_radius_br,
                        borderWidth: shapeProps.border_width,
                        borderColor: shapeProps.border_colour,
                        borderStyle: 'solid',
                        backgroundColor: shapeProps.fill,
                    }}>
                            
                    </div>
                </Html>
                
                <Rect
                    onClick={onSelect}
                    onTap={onSelect}
                    width={domDimensions.width}
                    height={domDimensions.height}
                    fill={'transparent'} 
                    ref={shapeRef}  
                    id={shapeProps.id}
                    name="shape"
                />
                 {containerChildren}
            </Group> 
                    
            {measurementLine && (
                <MeasurementLine
                    {...measurementLine}
                />
            )}

            { isSelected && (
                <Transformer
                    ref={trRef}
                    flipEnabled={false}
                    anchorCornerRadius={100}
                    rotateEnabled={false}
                    enabledAnchors={[ "bottom-right", "bottom-center", "middle-right"]}
                    anchorSize={10}
                    padding={8}
                    onTransform={onTransform}
                    onTransformEnd={onTransformEnd}
                    rotationSnaps={[0, 90, 180, 270]}
                    rotationSnapTolerance={10}
                    keepRatio={true}
                />
            )} 
       </>
    )
}

export default CoreContainer;