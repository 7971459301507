import React, {useState, useEffect} from 'react';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import '../../dropdown.css';
import '../../prototypemenu.css';
import { useEditorContext } from '../../../../../context/editor/editorcontext';
import { textfieldProtoOptions } from '../../../../../context/editor/prototypeoptions/prototypeoptions';

function SelectScreen({item, currentInteraction, onUpdate}) {
    const editorContext = useEditorContext();
    const [screen, setScreen] = useState(
        // Find screen name from ID if it exists
        currentInteraction?.navigateTo ? 
            editorContext.screens.find(s => s.id === currentInteraction.navigateTo)?.name || 'Select Screen' 
            : 'Select Screen'
    );

    const handle = (selectedScreen) => {
        setScreen(selectedScreen.name);
        // Only pass the screen ID
        onUpdate(selectedScreen.id);
    }

    return(
        <div className='interactionContainer'>
            <div style={{marginRight: 20, color: '#fff'}}>
                <h3 className='prototype-menu-title'>Navigate To</h3>
            </div>
            <DropdownMenu.Root>
                <DropdownMenu.Trigger asChild>
                    <button className="prototype-menu-dropdown-trigger" aria-label="Customise options">
                        {screen}
                    </button>
                </DropdownMenu.Trigger>
                <DropdownMenu.Portal>
                    <DropdownMenu.Content className="prototype-menu-dropdown-content">
                        {editorContext.screens.map((screen, i) => (
                            !screen.isVisible && (
                                <DropdownMenu.Item 
                                    key={i}
                                    className="prototype-menu-dropdown-item" 
                                    onClick={() => handle(screen)}
                                >
                                    {screen.name}
                                </DropdownMenu.Item>
                            )
                        ))}
                    </DropdownMenu.Content>
                </DropdownMenu.Portal>
            </DropdownMenu.Root>
        </div>
    )
}

export default SelectScreen;