import React, { useEffect, useState } from 'react';
import {useSortable} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';
import { useEditorContext } from '../../../../context/editor/editorcontext';

export function Layer (props) {

  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({id: props.id});
  const editorContext  = useEditorContext();

  const [selected, setSelected] = useState(false);
  const [hover, setHover] = useState(false);

  const [childSelected, setChildSelected] = useState(false);
  const [childHover, setChildHover] = useState(false);
  
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    color: '#fff',
    height: 24,
    marginBottom: 5,
    width: '100%',
    border: 'none',
    outline: 'none',
    textAlign: 'left',
    borderRadius: 5,
  };

  const childStyle = {
    transform: CSS.Transform.toString(transform),
    transition,
    color: '#fff',
    height: 24,
    marginBottom: 5,
    width: '100%',
    border: 'none',
    outline: 'none',
    textAlign: 'left',
    borderRadius: 5,
  };

  const handleClick = (element) => { 
    // set screen by id as isVisible
    console.log('clicked layer');
    editorContext.setCurrentActiveElement(element);
  }

  useEffect(()=>{
      setSelected(editorContext.activeElement?.id)
  },[editorContext.activeElement])
  
  return (
    <>
      <button 
        ref={setNodeRef} 
        style={{
          background: selected == props.element.id ? '#292B42' : hover ? '#30303D' : 'none', 
          marginLeft: props.type == 'child' ? 15 : 0,
          ...style
        }} 
        {...listeners} 
        {...attributes} 
        onMouseOver={()=> setHover(true)} 
        onMouseLeave={()=>setHover(false)} 
        onClick={()=>handleClick(props.element)} 
      >
          {props.element.name}
      </button>
      {/* {
        props.element.type == 'container' && 
        props.element.children.map((child)=>(
            <button 
              ref={setNodeRef} 
              style={{
                background: selected == child.id ? '#777FBD' : childHover == child.id ? '#4F547C' : 'none',
                ...childStyle
              }} 
              onMouseOver={()=>setChildHover(child.id)} 
              onMouseLeave={()=>setChildHover(null)} 
              onClick={()=>handleClick(child)}
            >
              {child.name}
            </button>
        ))
      } */}
    </>
  );
}

export default Layer;