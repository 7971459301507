import React, { useEffect, useState } from "react";
import { useEditorContext } from "../../../../../../../context/editor/editorcontext";
import '../../../elementpanels/elementpanel.css';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { ChevronDownIcon } from "@radix-ui/react-icons";

function ScrollBehaviorComponent({item}) {
    const editorContext = useEditorContext();
    const [behavior, setBehavior] = useState(item.scroll_behaviour || 'scroll');

    useEffect(() => {
        setBehavior(item.scroll_behaviour || 'scroll');
    }, [item]);

    const handleScrollBehaviorChange = (newBehavior) => {
        if(item?.id == editorContext.activeElement?.id){
            const props = {
                from: 'scrollBehavior',
                type: item.type,
                id: item.id,
                grouped: item.type !== 'container' ? item.grouped : null,
                grouped_by: item.type !== 'container' ? item.grouped_by : null,
                scroll_behaviour: newBehavior
            }
            setBehavior(newBehavior);
            editorContext.updateElementStyling(props);
        }
    };

    return (
        <div className="element-panel-section">
            <div className="element-panel-dropdown-trigger">
                <p>Scroll Behavior</p>
                <DropdownMenu.Root>
                    <DropdownMenu.Trigger asChild>
                        <button aria-label="Scroll behavior options">
                            {behavior}
                            <ChevronDownIcon color="#777FBD" width={16} height={16}/>
                        </button>
                    </DropdownMenu.Trigger>
                    <DropdownMenu.Portal>
                        <DropdownMenu.Content className="element-panel-dropdown-content">
                            <DropdownMenu.Item 
                                className="DropdownMenuItem"
                                onClick={() => handleScrollBehaviorChange('scroll')}
                            >
                                scroll
                            </DropdownMenu.Item>
                            <DropdownMenu.Item 
                                className="DropdownMenuItem"
                                onClick={() => handleScrollBehaviorChange('fixed')}
                            >
                                fixed
                            </DropdownMenu.Item>
                        </DropdownMenu.Content>
                    </DropdownMenu.Portal>
                </DropdownMenu.Root>
            </div>
        </div>
    );
}

export default ScrollBehaviorComponent; 