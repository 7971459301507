import React, {useState, useEffect} from 'react';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import '../../dropdown.css';
import './interaction.css';
import '../../prototypemenu.css';
import { useEditorContext } from '../../../../../context/editor/editorcontext';
import { textfieldProtoOptions, buttonProtoOptions, textProtoOptions, switchProtoOptions, imageProtoOptions, containerProtoOptions, designSystemProtoOptions } from '../../../../../context/editor/prototypeoptions/prototypeoptions';


// const getComponentCategory = (componentName) => {
//     for (const [category, info] of Object.entries(designSystemCategories)) {
//         if (info.components.includes(componentName)) {
//             return info;
//         }
//     }
//     return null;
// };


function Interaction({item, currentInteraction, onUpdate}) {
    const editorContext = useEditorContext();
    const [inter, setInter] = useState(currentInteraction?.interaction || 'Select Interaction');

    const handleInteraction = (interactionType) => {
        console.log(interactionType);
        setInter(interactionType);
        onUpdate(interactionType);
    }

    const renderOptions = (type) => {
        switch (type) {
            case 'textfield':
                return textfieldProtoOptions.interaction;
            case 'button':
                return buttonProtoOptions.interaction;
            case 'text':
                return textProtoOptions.interaction;
            case 'switch':
                return switchProtoOptions.interaction;
            case 'image':
                return imageProtoOptions.interaction;
            case 'container':
                return containerProtoOptions.interaction;
            case 'designSystem':
                return designSystemProtoOptions.interaction;
                // const category = getComponentCategory(item.component_name);
                // return category ? category.interaction : buttonProtoOptions.interaction;
            default:
                break;
        }
    }

    return(
        <div className='interactionContainer'>
            <div style={{marginRight: 20, color: '#fff'}}>
                <h3 className='prototype-menu-title'>Interaction</h3>
            </div>
            <DropdownMenu.Root>
                <DropdownMenu.Trigger asChild>
                    <button className="prototype-menu-dropdown-trigger" aria-label="Customise options">
                        {inter}
                    </button>
                </DropdownMenu.Trigger>
                <DropdownMenu.Portal>
                    <DropdownMenu.Content className="prototype-menu-dropdown-content">
                        {renderOptions(editorContext.activeElement.type).map((e) => (
                            <DropdownMenu.Item 
                                key={e}
                                className="prototype-menu-dropdown-item" 
                                onClick={() => handleInteraction(e)}
                            > 
                                {e} 
                            </DropdownMenu.Item>
                        ))}
                    </DropdownMenu.Content>
                </DropdownMenu.Portal>
            </DropdownMenu.Root>
        </div>
    )
}

export default Interaction;