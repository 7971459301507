import { Stage, Layer, Rect, Text as KonvaText, Circle, Group, Transformer, Image, Line } from 'react-konva';
import { Html } from 'react-konva-utils';
import { useEditorContext } from '../../../context/editor/editorcontext';
import { useState, useEffect } from 'react';
import { ArrowDownIcon, ArrowUpIcon, CaretDownIcon, CaretUpIcon } from '@radix-ui/react-icons';
import { useAuthContext } from '../../../context/auth/authcontext';
import Trigger from './options/trigger/trigger';
import Interaction from './options/interaction/interaction';
import PassData from './options/passdata/passdata';
import './prototypemenu.css';
import SelectScreen from './options/selectScreen/selectscreen';

function PrototypeMenu({item, xpos, ypos, width, height}) {

    const editorContext = useEditorContext();
    // menu option states
    const [trigger, setTrigger] = useState(null);
    const [interaction, setInteraction] = useState(null);
    const [navigateTo, setNavigateTo] = useState(null);
    const [showScreens, setShowScreens] = useState(false);

    // Initialize interactions from item.interactions if it exists, or create a new array
    const [interactions, setInteractions] = useState(
        item.interactions || [{
            id: '',
            trigger: null,
            interaction: null,
            navigateTo: null,
            passData: false
        }]
    );
    const [activeAccordion, setActiveAccordion] = useState(0);

    const menuX = parseFloat(xpos) + parseFloat(width) + 100;
    const menuY = parseFloat(ypos) + parseFloat(height/2);

    const x1 = parseFloat(xpos) + parseFloat(width) + 20
    const y1 = menuY
    const x2 = menuX - 10
    const y2 = menuY + 50;

    const handleUpdateInteraction = (index, field, value) => {
        
        const newInteractions = interactions.map((int, i) => 
            i === index ? {
                ...int,
                [field]: value
            } : int
        );
        
        setInteractions(newInteractions);
        console.log(newInteractions);
        editorContext.updateElementProto({
            ...item,
            interactions: newInteractions,
            type: item.type,
            grouped: item.grouped,
            grouped_by: item.grouped_by
        });
    };

    return(
        <>
            <Group 
                x={menuX} 
                y={menuY} 
            >   
                <Html>
                <div className='prototype-menu-container'>
                    <div className="prototype-menu-header">
                        <div className="prototype-menu-title">Prototype</div>
                        <button className="prototype-menu-add-button" onClick={() => {
                            setInteractions([...interactions, {
                                id: '',
                                trigger: null,
                                interaction: null,
                                navigateTo: null
                            }]);
                        }}>Add +</button>
                    </div>
                    
                    <div className="prototype-menu-interactions-container">
                        {interactions.map((interaction, index) => (
                            <div 
                                key={index} 
                                className={`prototype-menu-accordion-section ${activeAccordion === index ? 'active' : ''}`}
                            >
                                <div className="prototype-menu-accordion-header" onClick={() => setActiveAccordion(index)}>
                                    <div className="prototype-menu-header-content">
                                        <div className="prototype-menu-title">Id</div>
                                        <input
                                            type="text"
                                            className="prototype-menu-interaction-input"
                                            value={interaction.id}
                                            placeholder="Interaction Id"
                                            onClick={(e) => e.stopPropagation()}
                                            onChange={(e) => {
                                                handleUpdateInteraction(index, 'id', e.target.value);
                                            }}
                                        />
                                        {activeAccordion === index ? (
                                            <CaretUpIcon className="prototype-menu-arrow-indicator" />
                                        ) : (
                                            <CaretDownIcon className="prototype-menu-arrow-indicator" />
                                        )}
                                    </div>
                                </div>
                                {activeAccordion === index && (
                                    <>
                                        <Trigger 
                                            item={item}
                                            currentInteraction={interaction}
                                            onUpdate={(value) => handleUpdateInteraction(index, 'trigger', value)}
                                        />
                                        <Interaction 
                                            item={item}
                                            currentInteraction={interaction}
                                            onUpdate={(value) => handleUpdateInteraction(index, 'interaction', value)}
                                        />
                                        <SelectScreen 
                                            item={item}
                                            currentInteraction={interaction}
                                            onUpdate={(value) => handleUpdateInteraction(index, 'navigateTo', value)}
                                        />
                                    </>
                                )}
                            </div>
                        ))}
                    </div>
                </div>
                </Html>
            </Group>
            <Circle radius={5} x={x1} y={y1} fill={'#464A76'}/>
            <Circle radius={5} x={x2} y={y2} fill={'#464A76'}/>
            <Line x={0} y={0} points={[x1, y1, x2, y2]} stroke={'#464A76'}/>
        </>
         
    )

}
export default PrototypeMenu