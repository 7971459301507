import * as MuiIcons from '@mui/icons-material';
import * as MuiCore from '@mui/material';
import * as MuiDataGrid from '@mui/x-data-grid';
import * as MuiCharts from '@mui/x-charts';

// Separate icons and components
const iconComponents = Object.entries(MuiIcons).reduce((acc, [key, value]) => {
    // Add 'Icon' suffix to all icon components
    acc[`${key}Icon`] = value;
    return acc;
}, {});

// Create the combined scope
export const muiScope = {
    ...MuiCore,
    ...MuiCharts,
    ...MuiDataGrid,
    ...iconComponents, // Use the modified icon names
};

// Single source of truth for each design system
export const muiComponents = {
    name: 'Material UI',
    components: [
      {
        name: 'Button',
        category: 'inputs',
        codePreview: `()=>{
          return (
            <Button variant="contained" color="primary" style={{ width: "100%", height: "100%" }}>
              Button
            </Button>
          )
        }`,
        defaultProps: {
          width: 120,
          height: 36
        }
      },
      {
        name: 'TextField',
        category: 'inputs',
        codePreview: `()=>{
          return (
            <TextField
              label="Label"
              variant="filled"
              size="small"
              placeholder="Enter text"
              style={{ width: "100%", height: "100%", backgroundColor: '#fff'}}
            />
          )
        }`,
        defaultProps: {
          width: 200,
          height: 56
        }
      },
      {
        name: 'IconButton',
        category: 'inputs',
        codePreview: `()=>{
          return (
            <IconButton color="primary" style={{ width: "100%", height: "100%" }}>
              <MenuIcon/>
            </IconButton>
          )
        }`,
        defaultProps: {
          width: 40,
          height: 40
        }
      },
      {
        name: 'Card',
        category: 'surfaces',
        codePreview: `()=>{
          return (
            <Card elevation={1} style={{ width: "100%", height: "100%" }}>
              <CardContent>
                <Typography variant="h6">Card Title</Typography>
                <Typography variant="body2">Card content goes here</Typography>
              </CardContent>
            </Card>
          )
        }`,
        defaultProps: {
          width: 300,
          height: 200
        }
      },
      {
        name: 'AppBar',
        category: 'navigation',
        codePreview: `()=>{
          return (
            <AppBar position="static" style={{ width: "100%", height: "100%" }}>
              <Toolbar>
                <IconButton edge="start" color="inherit">
                  <Menu/>
                </IconButton>
                <Typography variant="h6">App</Typography>
              </Toolbar>
            </AppBar>
          )
        }`,
        defaultProps: {
          width: 400,
          height: 64
        }
      },
      {
        name: 'Switch',
        category: 'inputs',
        codePreview: `()=>{
          return (
            <Switch checked={true} color="primary" style={{ width: "100%", height: "100%" }} />
          )
        }`,
        defaultProps: {
          width: 58,
          height: 38
        }
      },
      {
        name: 'Select',
        category: 'inputs',
        codePreview: `()=>{
          return (
            <Select defaultValue={1} variant="outlined" size="small" style={{ width: "100%", height: "100%", backgroundColor: '#fff' }}>
              <MenuItem value={1}>Option 1</MenuItem>
              <MenuItem value={2}>Option 2</MenuItem>
            </Select>
          )
        }`,
        defaultProps: {
          width: 200,
          height: 40
        }
      },
      {
        name: 'Slider',
        category: 'inputs',
        codePreview: `()=>{
          return (
            <Slider defaultValue={50} style={{ width: "100%", height: "100%" }} />
          )
        }`,
        defaultProps: {
          width: 200,
          height: 4
        }
      },
      {
        name: 'Fab',
        category: 'inputs',
        codePreview: `()=>{
          return (
            <Fab 
              color="primary" 
              style={{ 
                width: '100%',
                height: '100%',
                aspectRatio: '1/1',
                padding: 0,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <AddIcon/>
            </Fab>
          )
        }`,
        defaultProps: {
          width: 56,
          height: 56
        }
      },
      {
        name: 'Paper',
        category: 'surfaces',
        codePreview: `()=>{
          return (
            <Paper elevation={2} style={{ width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
              Content
            </Paper>
          )
        }`,
        defaultProps: {
          width: 200,
          height: 100
        }
      },
      {
        name: 'List',
        category: 'data-display',
        codePreview: `()=>{
          return (
            <List style={{ width: "100%", height: "100%", overflow: "auto", backgroundColor: '#fff' }}>
              <ListItem>
                <ListItemText primary="List Item 1" />
              </ListItem>
              <Divider />
              <ListItem>
                <ListItemText primary="List Item 2" />
              </ListItem>
            </List>
          )
        }`,
        defaultProps: {
          width: 300,
          height: 160
        }
      },
      {
        name: 'Chip',
        category: 'data-display',
        codePreview: `()=>{
          return (
            <Chip label="Chip" color="primary" style={{ width: "100%", height: "100%" }} />
          )
        }`,
        defaultProps: {
          width: 100,
          height: 32
        }
      },
      {
        name: 'Avatar',
        category: 'data-display',
        codePreview: `()=>{
          return (
            <Avatar style={{ width: "100%", height: "100%" }}>U</Avatar>
          )
        }`,
        defaultProps: {
          width: 40,
          height: 40
        }
      },
      {
        name: 'CircularProgress',
        category: 'feedback',
        codePreview: `()=>{
          return (
            <CircularProgress style={{ width: "100%", height: "100%" }} />
          )
        }`,
        defaultProps: {
          width: 40,
          height: 40
        }
      },
      {
        name: 'LinearProgress',
        category: 'feedback',
        codePreview: `()=>{
          return (
            <LinearProgress style={{ width: "100%", height: "100%" }} />
          )
        }`,
        defaultProps: {
          width: 200,
          height: 4
        }
      },
      {
        name: 'Divider',
        category: 'data-display',
        codePreview: `()=>{
          return (
            <Divider style={{ width: "100%", height: "100%" }} />
          )
        }`,
        defaultProps: {
          width: 200,
          height: 1
        }
      },
      {
        name: 'Drawer',
        category: 'navigation',
        codePreview: `()=>{
          return (
            <Paper 
              elevation={4}
              style={{
                width: "100%",
                height: "100%",
                backgroundColor: '#fff',
                overflow: 'auto'
              }}
            >
              <List>
                <ListItem button>
                  <ListItemText primary="Menu Item 1" />
                </ListItem>
                <ListItem button>
                  <ListItemText primary="Menu Item 2" />
                </ListItem>
              </List>
            </Paper>
          )
        }`,
        defaultProps: {
          width: 240,
          height: 200
        }
      },
      {
        name: 'Tabs',
        category: 'navigation',
        codePreview: `()=>{
          return (
            <Tabs value={0} style={{ width: "100%", height: "100%" }}>
              <Tab label="Tab 1" />
              <Tab label="Tab 2" />
              <Tab label="Tab 3" />
            </Tabs>
          )
        }`,
        defaultProps: {
          width: 300,
          height: 48
        }
      },
      {
        name: 'Breadcrumbs',
        category: 'navigation',
        codePreview: `()=>{
          return (
            <Breadcrumbs style={{ width: "100%", height: "100%" }}>
              <Link href="#" color="inherit">Home</Link>
              <Link href="#" color="inherit">Category</Link>
              <Typography color="textPrimary">Current Page</Typography>
            </Breadcrumbs>
          )
        }`,
        defaultProps: {
          width: 300,
          height: 24
        }
      },
      {
        name: 'BottomNavigation',
        category: 'navigation',
        codePreview: `()=>{
          return (
            <BottomNavigation value={0} showLabels style={{ width: "100%", height: "100%" }}>
              <BottomNavigationAction label="Home" icon={<HomeIcon/>} />
              <BottomNavigationAction label="Favorites" icon={<FavoriteIcon/>} />
              <BottomNavigationAction label="Location" icon={<LocationOnIcon/>} />
            </BottomNavigation>
          )
        }`,
        defaultProps: {
          width: 360,
          height: 56
        }
      },
      {
        name: 'Stepper',
        category: 'navigation',
        codePreview: `()=>{
          return (
            <Stepper activeStep={1} style={{ width: "100%", height: "100%" }}>
              <Step><StepLabel>Step 1</StepLabel></Step>
              <Step><StepLabel>Step 2</StepLabel></Step>
              <Step><StepLabel>Step 3</StepLabel></Step>
            </Stepper>
          )
        }`,
        defaultProps: {
          width: 400,
          height: 72
        }
      },
      {
        name: 'Grid',
        category: 'layout',
        codePreview: `()=>{
          return (
            <Grid container spacing={2} style={{ width: "100%", height: "100%" }}>
              <Grid item xs={6}>
                <Paper style={{padding: '16px', height: '100%'}}>Grid Item 1</Paper>
              </Grid>
              <Grid item xs={6}>
                <Paper style={{padding: '16px', height: '100%'}}>Grid Item 2</Paper>
              </Grid>
            </Grid>
          )
        }`,
        defaultProps: {
          width: 400,
          height: 200
        }
      },
      {
        name: 'Container',
        category: 'layout',
        codePreview: `()=>{
          return (
            <Container style={{ width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
              <Typography>Container content</Typography>
            </Container>
          )
        }`,
        defaultProps: {
          width: 400,
          height: 200
        }
      },
      {
        name: 'Stack',
        category: 'layout',
        codePreview: `()=>{
          return (
            <Stack spacing={2} style={{ width: "100%", height: "100%" }}>
              <Paper style={{padding: '16px'}}>Item 1</Paper>
              <Paper style={{padding: '16px'}}>Item 2</Paper>
              <Paper style={{padding: '16px'}}>Item 3</Paper>
            </Stack>
          )
        }`,
        defaultProps: {
          width: 300,
          height: 200
        }
      },
      {
        name: 'SpeedDial',
        category: 'navigation',
        codePreview: `()=>{
          return (
            <SpeedDial
              ariaLabel="SpeedDial"
              icon={<SpeedDialIcon />}
              open={true}
              style={{ width: "100%", height: "100%" }}
            >
              <SpeedDialAction
                icon={<HomeIcon/>}
                tooltipTitle="Home"
              />
              <SpeedDialAction
                icon={<SettingsIcon/>}
                tooltipTitle="Settings"
              />
            </SpeedDial>
          )
        }`,
        defaultProps: {
          width: 56,
          height: 56
        }
      },
      {
        name: 'Accordion',
        category: 'surfaces',
        codePreview: `()=>{
          return (
            <Accordion style={{ width: "100%", height: "100%" }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                <Typography>Accordion Title</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>Accordion content goes here</Typography>
              </AccordionDetails>
            </Accordion>
          )
        }`,
        defaultProps: {
          width: 300,
          height: 150
        }
      }
    ]
};
