import {
    Affix,
    Alert,
    Anchor,
    App,
    AutoComplete,
    Avatar,
    BackTop,
    Badge,
    Breadcrumb,
    Button,
    Calendar,
    Card,
    Carousel,
    Cascader,
    Checkbox,
    Col,
    Collapse,
    ColorPicker,
    ConfigProvider,
    DatePicker,
    Descriptions,
    Divider,
    Drawer,
    Dropdown,
    Empty,
    Flex,
    FloatButton,
    Form,
    Grid,
    Image,
    Input,
    InputNumber,
    Layout,
    List,
    Mentions,
    Menu,
    Modal,
    Pagination,
    Popconfirm,
    Popover,
    Progress,
    QRCode,
    Radio,
    Rate,
    Result,
    Row,
    Segmented,
    Select,
    Skeleton,
    Slider,
    Space,
    Spin,
    Splitter,
    Statistic,
    Steps,
    Switch,
    Table,
    Tabs,
    Tag,
    TimePicker,
    Timeline,
    Tooltip,
    Tour,
    Transfer,
    Tree,
    TreeSelect,
    Typography,
    Upload,
    Watermark,
    message,
    notification,
    theme
} from 'antd';
import { DownOutlined, SmileOutlined } from '@ant-design/icons';
import { useState } from 'react';

export const antScope = {
    Affix,
    Alert,
    Anchor,
    App,
    AutoComplete,
    Avatar,
    BackTop,
    Badge,
    Breadcrumb,
    Button,
    Calendar,
    Card,
    Carousel,
    Cascader,
    Checkbox,
    Col,
    Collapse,
    ColorPicker,
    ConfigProvider,
    DatePicker,
    Descriptions,
    Divider,
    Drawer,
    Dropdown,
    Empty,
    Flex,
    FloatButton,
    Form,
    Grid,
    Image,
    Input,
    InputNumber,
    Layout,
    List,
    Mentions,
    Menu,
    Modal,
    Pagination,
    Popconfirm,
    Popover,
    Progress,
    QRCode,
    Radio,
    Rate,
    Result,
    Row,
    Segmented,
    Select,
    Skeleton,
    Slider,
    Space,
    Spin,
    Splitter,
    Statistic,
    Steps,
    Switch,
    Table,
    Tabs,
    Tag,
    TimePicker,
    Timeline,
    Tooltip,
    Tour,
    Transfer,
    Tree,
    TreeSelect,
    Typography,
    Upload,
    Watermark,
    message,
    notification,
    theme
};

export const antComponents = {
    name: 'Ant Design',
    components: [
        {
            name: 'Button',
            category: 'general',
            codePreview: `()=>{
  return (
    <Button type="primary">Primary Button</Button>
  )
}`,
            defaultProps: {
                width: 120,
                height: 40
            }
        },
        {
            name: 'Alert',
            category: 'feedback',
            codePreview: `()=>{
  return (
    <Alert message="Success Text" type="success" />
  )
}`,
            defaultProps: {
                width: 300,
                height: 50
            }
        },
        {
            name: 'Card',
            category: 'data-display',
            codePreview: `()=>{
  return (
    <Card title="Card Title">
      <p>Card content</p>
    </Card>
  )
}`,
            defaultProps: {
                width: 300,
                height: 200
            }
        },
        {
            name: 'Progress',
            category: 'feedback',
            codePreview: `()=>{
  return (
    <Progress percent={70} />
  )
}`,
            defaultProps: {
                width: 300,
                height: 40
            }
        },
        {
            name: 'Rate',
            category: 'data-entry',
            codePreview: `()=>{
  return (
    <Rate defaultValue={3} />
  )
}`,
            defaultProps: {
                width: 200,
                height: 40
            }
        },
        {
            name: 'Tag',
            category: 'data-display',
            codePreview: `()=>{
  return (
    <Tag color="blue">Tag</Tag>
  )
}`,
            defaultProps: {
                width: 60,
                height: 30
            }
        },
        {
            name: 'Input',
            category: 'data-entry',
            codePreview: `()=>{
  return (
    <Input placeholder="Basic usage" />
  )
}`,
            defaultProps: {
                width: 200,
                height: 40
            }
        },
        {
            name: 'Checkbox',
            category: 'data-entry',
            codePreview: `()=>{
  return (
    <Checkbox>Checkbox</Checkbox>
  )
}`,
            defaultProps: {
                width: 100,
                height: 40
            }
        },
        {
            name: 'Radio',
            category: 'data-entry',
            codePreview: `()=>{
  return (
    <Radio>Radio</Radio>
  )
}`,
            defaultProps: {
                width: 100,
                height: 40
            }
        },
        {
            name: 'Divider',
            category: 'layout',
            codePreview: `()=>{
  return (
    <Divider />
  )
}`,
            defaultProps: {
                width: 300,
                height: 20
            }
        },
        {
            name: 'Breadcrumb',
            category: 'navigation',
            codePreview: `()=>{
  return (
    <Breadcrumb>
      <Breadcrumb.Item>sample</Breadcrumb.Item>
      <Breadcrumb.Item>sample</Breadcrumb.Item>
    </Breadcrumb>
  )
}`,
            defaultProps: {
                width: 300,
                height: 20
            }
        },
        {
            name: 'Dropdown',
            category: 'navigation',
            codePreview: `()=>{
  return (
    <Dropdown menu={{items: [{key: "1", label: "Option 1"}, {key: "2", label: "Option 2"}]}}>
      <Button style={{width: "100%"}}>Dropdown</Button>
    </Dropdown>
  )
}`,
            defaultProps: {
                width: 300,
                height: 40
            }
        },
        {
            name: 'Select',
            category: 'data-entry',
            codePreview: `()=>{
  return (
    <Select style={{width: "100%"}} defaultValue="Select Option">
      <Select.Option value="option1">Option 1</Select.Option>
      <Select.Option value="option2">Option 2</Select.Option>
      <Select.Option value="option3">Option 3</Select.Option>
    </Select>
  )
}`,
            defaultProps: {
                width: 300,
                height: 40
            }
        },
        {
            name: 'Switch',
            category: 'data-entry',
            codePreview: `()=>{
  return (
    <Switch defaultChecked />
  )
}`,
            defaultProps: {
                width: 60,
                height: 30
            }
        },
        {
            name: 'Upload',
            category: 'data-entry',
            codePreview: `()=>{
  return (
    <Upload>
      <Button>Click to Upload</Button>
    </Upload>
  )
}`,
            defaultProps: {
                width: 200,
                height: 40
            }
        },
        {
            name: 'TimePicker',
            category: 'data-entry',
            codePreview: `()=>{
  return (
    <TimePicker style={{width: "100%"}} />
  )
}`,
            defaultProps: {
                width: 200,
                height: 40
            }
        },
        {
            name: 'Calendar',
            category: 'data-display',
            codePreview: `()=>{
  return (
    <Calendar fullscreen={false} />
  )
}`,
            defaultProps: {
                width: 300,
                height: 300
            }
        },
        {
            name: 'Image',
            category: 'data-display',
            codePreview: `()=>{
  return (
    <Image width={200} src="https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png" />
  )
}`,
            defaultProps: {
                width: 200,
                height: 200
            }
        },
        {
            name: 'Collapse',
            category: 'data-display',
            codePreview: `()=>{
  return (
    <Collapse>
      <Collapse.Panel header="Panel 1" key="1">
        <p>Panel content 1</p>
      </Collapse.Panel>
      <Collapse.Panel header="Panel 2" key="2">
        <p>Panel content 2</p>
      </Collapse.Panel>
    </Collapse>
  )
}`,
            defaultProps: {
                width: 300,
                height: 200
            }
        },
        {
            name: 'QRCode',
            category: 'data-display',
            codePreview: `()=>{
  return (
    <QRCode value="https://ant.design/" />
  )
}`,
            defaultProps: {
                width: 160,
                height: 160
            }
        },
        {
            name: 'Segmented',
            category: 'data-display',
            codePreview: `()=>{
  return (
    <Segmented options={["Daily", "Weekly", "Monthly"]} />
  )
}`,
            defaultProps: {
                width: 300,
                height: 40
            }
        },
        {
            name: 'Statistic',
            category: 'data-display',
            codePreview: `()=>{
  return (
    <Statistic title="Active Users" value={112893} />
  )
}`,
            defaultProps: {
                width: 200,
                height: 100
            }
        },
        {
            name: 'Table',
            category: 'data-display',
            codePreview: `()=>{
  return (
    <Table 
      columns={[
        { title: 'Name', dataIndex: 'name' },
        { title: 'Age', dataIndex: 'age' }
      ]} 
      dataSource={[
        { key: '1', name: 'John', age: 32 },
        { key: '2', name: 'Jane', age: 28 }
      ]} 
    />
  )
}`,
            defaultProps: {
                width: 400,
                height: 300
            }
        },
        {
            name: 'Tabs',
            category: 'data-display',
            codePreview: `()=>{
  return (
    <Tabs>
      <Tabs.TabPane tab="Tab 1" key="1">Content of Tab 1</Tabs.TabPane>
      <Tabs.TabPane tab="Tab 2" key="2">Content of Tab 2</Tabs.TabPane>
    </Tabs>
  )
}`,
            defaultProps: {
                width: 300,
                height: 200
            }
        },
        {
            name: 'Timeline',
            category: 'data-display',
            codePreview: `()=>{
  return (
    <Timeline>
      <Timeline.Item>Create a service</Timeline.Item>
      <Timeline.Item>Solve initial network problems</Timeline.Item>
      <Timeline.Item>Technical testing</Timeline.Item>
    </Timeline>
  )
}`,
            defaultProps: {
                width: 300,
                height: 200
            }
        },
        {
            name: 'Tooltip',
            category: 'data-display',
            codePreview: `()=>{
  return (
    <Tooltip title="Tooltip content">
      <Button>Hover me</Button>
    </Tooltip>
  )
}`,
            defaultProps: {
                width: 120,
                height: 40
            }
        },
        {
            name: 'Popconfirm',
            category: 'feedback',
            codePreview: `()=>{
  return (
    <Popconfirm title="Are you sure?" okText="Yes" cancelText="No">
      <Button>Delete</Button>
    </Popconfirm>
  )
}`,
            defaultProps: {
                width: 100,
                height: 40
            }
        },
        {
            name: 'Pagination',
            category: 'navigation',
            codePreview: `()=>{
  return (
    <Pagination defaultCurrent={1} total={50} />
  )
}`,
            defaultProps: {
                width: 300,
                height: 40
            }
        },
        {
            name: 'Menu',
            category: 'navigation',
            codePreview: `()=>{
  return (
    <Menu mode="inline" style={{width: '100%'}}>
      <Menu.Item key="1">Menu Item 1</Menu.Item>
      <Menu.Item key="2">Menu Item 2</Menu.Item>
      <Menu.SubMenu key="sub1" title="Submenu">
        <Menu.Item key="3">Submenu Item 1</Menu.Item>
        <Menu.Item key="4">Submenu Item 2</Menu.Item>
      </Menu.SubMenu>
    </Menu>
  )
}`,
            defaultProps: {
                width: 200,
                height: 200
            }
        },
    ]
};