import React, {useRef, useEffect, useLayoutEffect, useState, memo} from "react";
import { Html } from 'react-konva-utils';
import { Stage, Layer, Rect, Text as KonvaText, Circle, Group, Transformer } from 'react-konva';
import { useMeasure } from "react-use";
import { Portal } from 'react-konva-utils';
import { useEditorContext } from "../../../../../context/editor/editorcontext";
import { calcWidthHeight } from "../../../../../utils/editor/calcWidthHeight";
import { Item } from "@radix-ui/react-dropdown-menu";
import MeasurementLine from '../../../utils/measurementline';
import { useMeasurementTool } from '../../../utils/useMeasurementTool';

function CoreText({stageRef, keyVal, shapeProps, setDomensions, isSelected, setSelectedId, elWidth, elHeight,  onSelect, onDragMove, onDragEnd, onTransform, onTransformEnd }) {

    const shapeRef = useRef();
    const parentRef = useRef();
    const trRef = useRef();

    const editorContext = useEditorContext();

    const [domDimensions, setDomDimensions] = useState({width:50, height:50})
    const [dimensionRef, { x, y, width, height, top, right, bottom, left }] = useMeasure();

    const [loaded, setLoaded] = useState(false);
    const [loadedWidth, setLoadedWidth] = useState(null);
    const [loadedHeight, setLoadedHeight] = useState(null);
    
    //console.log(shapeProps.font_weight, parseInt(shapeProps.font_weight))

    const measurementLine = useMeasurementTool(stageRef, isSelected, {
        xpos: shapeProps.xpos,
        ypos: shapeProps.ypos,
        width: elWidth,
        height: elHeight,
        grouped: shapeProps.grouped,
        grouped_by: shapeProps.grouped_by
    });

    useEffect(()=>{
        setDomDimensions({
            width: width,
            height: height
        });
        setDomensions({
            width: width,
            height: height
        })
    },[width, height])
  
    useEffect(() => {
      if (isSelected) {
        trRef.current.nodes([shapeRef.current]);
        trRef.current.getLayer().batchDraw();
      }
    }, [isSelected]);
    
    useEffect(()=>{
        if(loaded == false){
            setLoadedWidth(shapeProps.width);
            setLoadedHeight(shapeProps.height);
            setLoaded(true);
        }
    },[])

    useEffect(()=>{
        if(editorContext.activeElement?.id == shapeProps.id){
            //console.log('triggering element', shapeProps.show_value )
            setSelectedId(shapeProps.id);
        }
    },[editorContext.activeElement]);

    useEffect(() => {
        const handleKeyDown = (event) => {
            if(isSelected){
                // console.log('keys', key)
                if ((event.metaKey || event.ctrlKey) && event.code === 'Backspace') {
                    const props = {
                        id: shapeProps.id,
                        type: shapeProps.type,
                        grouped: shapeProps.grouped,
                        grouped_by: shapeProps.grouped_by,
                    }
                    editorContext.deleteElement(props);
                  }              
            }
        }
        //add a global event for `keydown`
        window.addEventListener('keydown', handleKeyDown)
       //clean up after component unmounted
       return () => window.removeEventListener('keydown', handleKeyDown)
     }, [isSelected])

     React.useEffect(() => {
        if(!isSelected) return
        const konvaContent = stageRef.content;
        const transformerElement = trRef.current.getNode().getCanvas()._canvas;
        
        transformerElement.style.position = 'absolute';
        transformerElement.style.top = '0';
        transformerElement.style.left = '0';
        transformerElement.style.zIndex = '9999';
        transformerElement.style.pointerEvents = 'none'; // Add this line

        
        konvaContent.appendChild(transformerElement);
    }, [isSelected]);


    return(
        <>
            <Group 
                key={keyVal}
                draggable={true} 
                onDragMove={onDragMove} 
                onDragEnd={onDragEnd} 
                x={shapeProps.xpos}
                y={shapeProps.ypos}
                width={parseFloat(elWidth)}
                height={parseFloat(elHeight)}
            >
                <Html divProps={{ style: { pointerEvents: "none",}}}>
                    <div ref={dimensionRef} style={{
                        width: parseFloat(elWidth), 
                        height: parseFloat(elHeight), 
                        padding: 0,
                        margin: 0,
                    }}>
                        <p 
                            style={{
                                width: '100%', 
                                height: '100%', 
                                fontFamily: shapeProps.font, 
                                fontWeight: parseInt(shapeProps.font_weight),
                                fontSize: parseInt(shapeProps.font_size),
                                color: shapeProps.font_colour,
                                lineHeight: parseFloat(shapeProps.line_height),
                                letterSpacing: parseFloat(shapeProps.letter_spacing),
                                textAlign: shapeProps.text_alignment,
                                whiteSpace: 'pre-wrap',
                                margin: 0,
                                padding: 0
                            }}
                        >
                            {shapeProps.value}
                        </p>
                    </div>
                </Html>
                <Rect
                    onClick={onSelect}
                    onTap={onSelect}
                    width={domDimensions.width}
                    height={domDimensions.height}
                    fill={'transparent'} 
                    ref={shapeRef}
                    name="shape"  
                />
            </Group> 

            {measurementLine && (
                <MeasurementLine
                    {...measurementLine}
                />
            )}

            { isSelected && (
                <Transformer
                    ref={trRef}
                    flipEnabled={false}
                    anchorCornerRadius={100}
                    rotateEnabled={false}
                    enabledAnchors={[ "bottom-right", "bottom-center", "middle-right"]}
                    anchorSize={10}
                    padding={8}
                    onTransform={onTransform}
                    onTransformEnd={onTransformEnd}
                    rotationSnaps={[0, 90, 180, 270]}
                    rotationSnapTolerance={10}
                    keepRatio={true}
                />
            )} 
       </>
    )
}

export default memo(CoreText)