import '../centerpanel.css';
import { TokensIcon, MixIcon } from '@radix-ui/react-icons'
import * as Popover from '@radix-ui/react-popover';
import { useEditorContext } from '../../../../context/editor/editorcontext';
import { useState } from 'react';
import textImg from '../../../../resources/editor/elements/text.svg';
import imageImg from '../../../../resources/editor/elements/image.svg';
import textfieldImg from '../../../../resources/editor/elements/textfield.svg';
import buttonImg from '../../../../resources/editor/elements/button.svg';
import switchImg from '../../../../resources/editor/elements/switch.svg';
import containerImg from '../../../../resources/editor/elements/container.svg';

function ElementsModal(){

    const editorContext = useEditorContext();
    const [openPanel, setOpenPanel] = useState(false);

    const handleOnClick = (type) => {
        //add element on button click        
        editorContext.addElement(type);
        setOpenPanel(false);
    }

    return(
        <div className='cp-button cp-button-end-left'>
            <Popover.Root open={openPanel} onOpenChange={setOpenPanel}>
            <Popover.Trigger className='popoverTrigger'>
                <TokensIcon color='#fff' width={24} height={24}/>
            </Popover.Trigger>
            <Popover.Anchor />
                <Popover.Portal>
                    <Popover.Content className='elementsContainer'>
                        <div className='elementsSection'>
                            <h4 style={{color:'#fff'}} >Content</h4>
                            <div className='elementsSectionContent'>
                                <div className='element-preview-container elementsSectionText' onClick={()=>{handleOnClick('text')}}>
                                    <img src={textImg}/>
                                    <h3>Text</h3>
                                </div>
                                <div className='element-preview-container elementsSectionImage' onClick={()=>{handleOnClick('image')}}>
                                    <img src={imageImg}/>
                                    <h3>Image</h3>
                                </div>
                            </div>
                        </div>
                        <div className='elementsSection'>
                            <h4 style={{color:'#fff'}} >Controllers</h4>
                            <div className='elementsSectionContent'>
                                <div className='element-preview-container elementsSectionTextfield' onClick={()=>{handleOnClick('textfield')}}>
                                    <img src={textfieldImg}/>
                                    <h3>Text Field</h3>
                                </div>
                                <div className='element-preview-container elementsSectionButton' onClick={()=>{handleOnClick('button')}}>
                                    <img src={buttonImg}/>
                                    <h3>Button</h3>
                                </div>
                                <div className='element-preview-container elementsSectionSwitch' onClick={()=>{handleOnClick('switch')}}>
                                    <img src={switchImg}/>
                                    <h3>Switch</h3>
                                </div>
                            </div>
                            
                        </div>
                        <div className='elementsSection'>
                            <h4 style={{color:'#fff'}}>Layout</h4>
                            <div className='elementsSectionContent'>
                                <div className='element-preview-container elementsSectionContainer' onClick={()=>{handleOnClick('container')}}>
                                    <img src={containerImg}/>
                                    <h3>Container</h3>
                                </div>
                            </div>
                        </div>
                        </Popover.Content>
                </Popover.Portal>
            </Popover.Root>
        </div>
    );
}

export default ElementsModal;