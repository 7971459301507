// Threshold in pixels for when snapping should activate
const SNAP_THRESHOLD = 0.5;

export const onDragMoveOS = (stageRef, selectedShape) => {
  if (!stageRef || !selectedShape) return [];

  const selectedRect = selectedShape.children.find(child => 
    child.attrs && child.attrs.name === 'shape'
  );
  console.log('Selected rect:', selectedRect);
  
  const otherShapes = stageRef.find('.shape').filter(shape => shape !== selectedRect);
  const stage = stageRef;
  const stageScale = stage.scaleX();
  const stagePos = stage.position();

  let snapLinesMap = new Map();

  const getAbsolutePosition = (shape) => {
    const pos = shape.absolutePosition();
    return {
      x: (pos.x - stagePos.x) / stageScale,
      y: (pos.y - stagePos.y) / stageScale
    };
  };

  const addSnapLine = (type, position) => {
    const key = `${type}-${position}`;
    snapLinesMap.set(key, {
      type: type,
      x: type === 'vertical' ? position : 0,
      y: type === 'horizontal' ? position : 0
    });
  };

  const selectedAbsPos = getAbsolutePosition(selectedShape);
  const selectedWidth = selectedShape.width();
  const selectedHeight = selectedShape.height();
  
  const selectedCenterX = selectedAbsPos.x + (selectedWidth / 2);
  const selectedCenterY = selectedAbsPos.y + (selectedHeight / 2);

  // console.log('Selected shape:', {
  //   position: selectedAbsPos,
  //   width: selectedWidth,
  //   height: selectedHeight
  // });

  otherShapes.forEach(otherShape => {
    if (otherShape === selectedRect) return;

    const otherPos = getAbsolutePosition(otherShape);
    const otherWidth = otherShape.width();
    const otherHeight = otherShape.height();
    
    const otherCenterX = otherPos.x + (otherWidth / 2);
    const otherCenterY = otherPos.y + (otherHeight / 2);

    // console.log('Comparing with:', {
    //   position: otherPos,
    //   width: otherWidth,
    //   height: otherHeight
    // });

    const leftDiff = Math.abs(selectedAbsPos.x - otherPos.x);
    const rightDiff = Math.abs((selectedAbsPos.x + selectedWidth) - (otherPos.x + otherWidth));
    const topDiff = Math.abs(selectedAbsPos.y - otherPos.y);
    const bottomDiff = Math.abs((selectedAbsPos.y + selectedHeight) - (otherPos.y + otherHeight));
    const horizontalCenterDiff = Math.abs(selectedCenterY - otherCenterY);
    const verticalCenterDiff = Math.abs(selectedCenterX - otherCenterX);

    if (leftDiff < SNAP_THRESHOLD) {
      addSnapLine('vertical', otherPos.x);
    }
    if (rightDiff < SNAP_THRESHOLD) {
      addSnapLine('vertical', otherPos.x + otherWidth);
    }
    if (topDiff < SNAP_THRESHOLD) {
      addSnapLine('horizontal', otherPos.y);
    }
    if (bottomDiff < SNAP_THRESHOLD) {
      addSnapLine('horizontal', otherPos.y + otherHeight);
    }
    if (horizontalCenterDiff < SNAP_THRESHOLD) {
      addSnapLine('horizontal', otherCenterY);
    }
    if (verticalCenterDiff < SNAP_THRESHOLD) {
      addSnapLine('vertical', otherCenterX);
    }
  });

  return Array.from(snapLinesMap.values());
};