export function RepositionElements (designScreen, playerscreen) {
  // get xpos ypos from design screen
  // get xpos ypos from player screen 
  // take design screen from proto screen

  const xpos = playerscreen.xpos - designScreen.xpos;
  const ypos = playerscreen.ypos - designScreen.ypos;

  //console.log(xpos, ypos)

  return {xpos: xpos, ypos: ypos};
}

export function adjustElementPosition (element, currentScreen, xy, screenWidth) {

  // console.log('Element position:', {
  //   xpos: element.xpos,
  //   screenPos: xy.xpos,
  //   width: element.width,
  //   totalWidth: element.width,
  //   screenWidth: screenWidth,
  //   overflow: xy.xpos - element.xpos,
  //   isOffRight: element.xpos > screenWidth,
  //   isOffLeft: element.xpos < 0
  // });
  if(element.screen == currentScreen.id){
    // console.log('element is in an active screen')
      return xy.xpos + element.xpos
  }else{
    if (element.width > screenWidth || element.xpos > xy.xpos) {
      // Calculate how much to adjust by
      // console.log('element is off the screen', element.xpos, screenWidth);
      const diff = Math.abs(xy.xpos - element.xpos);  // Ensure diff is never negative
      // console.log('this is the diff', diff);
      return xy.xpos + element.xpos + screenWidth + diff;
    }else{
      // console.log('element is not off the screen and not in an active screen')
      return xy.xpos + element.xpos + screenWidth
    }
    
  }
  //element.screen == prototypeContext.currentRenderedScreen.id ? xy.xpos + element.xpos : xy.xpos + element.xpos + prototypeContext.screens[0].width
}