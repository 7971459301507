import React, {useRef, useEffect, useLayoutEffect, useState, memo} from "react";
import { Html } from 'react-konva-utils';
import { Stage, Layer, Rect, Text as KonvaText, Circle, Group, Transformer } from 'react-konva';
import { usePrototypeContext } from "../../../../../context/prototype/prototypecontext";
import { prototypeSwitcher } from "../../../utils/prototypeGenerator";
import { useMeasure } from "react-use";
import { RepositionElements, adjustElementPosition } from "../../../utils/repositionElements";
import { Spring, animated, useSpring, useSpringValue} from '@react-spring/konva';
import Konva from "konva";
import executeGlobalAnim from "../../../utils/executeGlobalAnim";

function CorePlayerButton({key, element, screenWidth, screenHeight, canvasX, canvasY, scale, stageY}) {

   
    const prototypeContext = usePrototypeContext();
    const [domDimensions, setDomDimensions] = useState({width:50, height:50});
    const [dimensionRef, { x, y, width, height, top, right, bottom, left }] = useMeasure();

    const [activateElement, setActivateElement] = useState(false);
    const [value, setValue] = useState(element.value)
    const inputRef = React.useRef();

    const [translatedX, setTranslatedX] = useState(0);
    const [translatedY, setTranslatedY] = useState(0);

    const [elementX, setElementX] = useState(0);
    const [elementY, setElementY] = useState(0);

    const [hoverState, setHoverState] = useState(false);
    
    const animRef = useRef();

    const runAnim = (xval, animTrigger, anim) => {
        const diff = Math.abs(translatedX - element.xpos);
        const finalExitPos = translatedX + element.xpos + screenWidth + diff;
        
        new Konva.Tween({
            node: animRef.current,
            easing: Konva.Easings.Linear,
            duration: 0.2,
            x: animTrigger.exitScreen == element.screen && anim == 'TransitionOutLeftRight' 
                ? -element.width
                : xval,
            onFinish: () => {
                if(animTrigger.exitScreen == element.screen && anim == 'TransitionOutLeftRight'){
                    setElementX(finalExitPos);
                } else {
                    setElementX(xval);
                }
            }
        }).play();
    }

    useEffect(()=>{
            //console.log(element);
            const elementScreen = prototypeContext.screens.find((i) => i.id == element.screen);
            const screen1 = {xpos: elementScreen?.xpos, ypos: elementScreen?.ypos} 
            const screen2 = {xpos: canvasX - screenWidth/2, ypos: canvasY - screenHeight/2}
            //console.log(screen1, screen2)
            const xy = RepositionElements(screen1, screen2);
            const newPos = adjustElementPosition(element, prototypeContext.currentRenderedScreen, xy, screenWidth)

            setTranslatedX(xy.xpos);
            setTranslatedY(xy.ypos);
            setElementX(newPos);
            setElementY(xy.ypos + element.ypos);
            //console.log(xy, xy.ypos, element.ypos);
    },[])

    useEffect(()=>{
        setDomDimensions({
            width: width,
            height: height
        });
    },[width, height]);

    useEffect(()=>{
        //wait for animation trigger
        // anim trigger
        if( prototypeContext.animTrigger.type == 'global' && prototypeContext.animTrigger.exitScreen == element.screen && prototypeContext.animTrigger.sequence === 'exit')
        {
            //animate all elements that are in the current screen
            const globalAnim = executeGlobalAnim(prototypeContext.animTrigger.exitScreenAnimation, element, translatedX, prototypeContext.animTrigger);
            runAnim(globalAnim, prototypeContext.animTrigger, prototypeContext.animTrigger.exitScreenAnimation);
            setActivateElement(false);

        }else if (prototypeContext.animTrigger.type == 'global' && prototypeContext.animTrigger.entranceScreen == element.screen && prototypeContext.animTrigger.sequence === 'entrance')
        {
            const globalAnim = executeGlobalAnim(prototypeContext.animTrigger.entranceScreenAnimation, element, translatedX, prototypeContext.animTrigger);
            runAnim(globalAnim, prototypeContext.animTrigger, prototypeContext.animTrigger.entranceScreenAnimation);

            setActivateElement(false);
            // stop state being called multiple times
            if(prototypeContext.currentRenderedScreen.id != element.screen){
                const screen = prototypeContext.screens.filter((i)=>i.id == element.screen);
                prototypeContext.setCurrentScreen(screen[0]);
                prototypeContext.setCurrentRenderedElements(element);
            }
            
        }else if(prototypeContext.animTrigger.type == 'individual' && prototypeContext.animTrigger.originElementId == element.id){
            // animate only this element
            console.log('button individual', element.id);
        }
    },[prototypeContext.animTrigger])

    const handlePrototype = (event, element) => {
        const res = prototypeContext.triggerPrototype(event, element);

        if(res == false && event == 'On click'){
            setActivateElement(true);
        }else if(res == true){
            setActivateElement(true);
        }else{
            setActivateElement(false);
        }
    }

    return(
        <>
            {
                <Group 
                    key={key}
                    ref={animRef}
                    x={element.grouped ? element.xpos : elementX}
                    y={element.grouped ? element.ypos : element.scroll_behaviour === 'fixed' ? elementY - (stageY/scale) : elementY}
                    width={element.width}
                    height={element.height}
                >
                    <Html divProps={{ style:{pointerEvents: activateElement ? 'auto' : 'none'}}}>
                        <div ref={dimensionRef}>
                            <button 
                                ref={inputRef}
                                style={{
                                    width: element.width, 
                                    height: element.height, 
                                    fontFamily: element.font, 
                                    fontSize: parseInt(element.font_size),
                                    color: element.font_colour,
                                    borderTopLeftRadius: element.border_radius_tl,
                                    borderTopRightRadius: element.border_radius_tr,
                                    borderBottomLeftRadius: element.border_radius_bl,
                                    borderBottomRightRadius: element.border_radius_br,
                                    borderWidth: hoverState ? element.hover_border_width : element.border_width,
                                    borderColor: hoverState ? element.hover_border_colour : element.border_colour,
                                    borderStyle: 'solid',
                                    backgroundColor: hoverState ? element.hover_fill : element.fill, 
                                }}  
                            >{element.value}</button> 
                        </div>
                    </Html>
                    <Rect
                        onClick={()=>{handlePrototype('On click', element)}}
                        width={domDimensions.width}
                        height={domDimensions.height}
                        fill={'transparent'}
                        onMouseEnter={()=>{
                            setHoverState(true);
                        }}

                        onMouseLeave={()=>{
                            setHoverState(false);
                        }}
                        onTouchStart={()=>{
                            setHoverState(true);
                        }}

                        onTouchEnd={()=>{
                            setHoverState(false);
                        }}
                    />
                </Group>  

            }
        </>
    )
}

export default memo(CorePlayerButton);
