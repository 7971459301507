import React, { useEffect, useState } from 'react';
import './stylepanel.css';
import Fillbordercomponent from './optioncomponents/fillbordercomponent/fillbordercomponent';
import BorderRadiusComponent from './optioncomponents/borderradiuscomponent/borderradiuscomponent';
import ScrollBehaviorComponent from './optioncomponents/scrollbehaviorcomponent/scrollbehaviorcomponent';
import '../elementpanels/elementpanel.css'

function StylePanel ({item}){
    return(
       <div className='stylepanelcontainer element-panel-container'>
            <div className="element-panel-title">
                <p>Styles</p>
            </div>
            <ScrollBehaviorComponent item={item}/>
            {item.type !== 'image' && item.type !== 'switch' && item.type !== 'designSystem' ?
                <Fillbordercomponent item={item}/>
                :
                null
            }
            {item.type !== 'designSystem' ?
                <BorderRadiusComponent item={item}/>
                :
                null
            }
       </div>
    );
}

export default StylePanel;