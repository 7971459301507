
export const textfieldProtoOptions = {
    trigger:['On enter', 'On click'],
    interaction:['Navigate to screen']
}

export const buttonProtoOptions = {
    trigger:['On click'],
    interaction:['Navigate to screen']
}

export const textProtoOptions = {
    trigger:['On click'],
    interaction:['Navigate to screen']
}

export const switchProtoOptions = {
    trigger:['Switch on', 'Switch off'],
    interaction:['Navigate to screen']
}

export const imageProtoOptions = {
    trigger:['On click'],
    interaction:['Navigate to screen']
}

export const containerProtoOptions = {
    trigger:['On click'],
    interaction:['Navigate to screen']
}

export const designSystemProtoOptions = {
    trigger:['On click'],
    interaction:['Navigate to screen']
}

// export const designSystemCategories = {
//     'selector_category': {
//         trigger: ['On click'],
//         components: ['dropdown', 'select', 'combobox', 'autocomplete', 'radio', 'checkbox'],
//         interaction:['Navigate to screen']
//     },
//     'navigation_category': {
//         trigger: ['On click'],
//         components: ['tabs', 'drawer', 'navbar', 'pagination', 'breadcrumbs', 'menu'],
//         interaction:['Navigate to screen']
//     },
//     'button_category': {
//         trigger: ['On click'],
//         components: ['button', 'icon-button', 'link', 'fab'],
//         interaction:['Navigate to screen']
//     },
//     'input_category': {
//         trigger: ['On click'],
//         components: ['textfield', 'textarea', 'number-input', 'search'],
//         interaction:['Navigate to screen']
//     },
//     'slider_category': {
//         trigger: ['On click'],
//         components: ['slider', 'range-slider', 'progress'],
//         interaction:['Navigate to screen']
//     },
//     'toggle_category': {
//         trigger: ['On click'],
//         components: ['switch', 'toggle-button', 'accordion', 'collapse'],
//         interaction:['Navigate to screen']
//     }
// };

// // Helper function to get category for a component
// export const getComponentCategory = (componentType) => {
//     for (const [category, info] of Object.entries(designSystemCategories)) {
//         if (info.components.includes(componentType)) {
//             return category;
//         }
//     }
//     return null;
// };