import React, { useEffect, useState } from "react"
import * as Popover from '@radix-ui/react-popover';
import { HomeIcon, PlusIcon, ArrowDownIcon, CaretDownIcon } from '@radix-ui/react-icons';
import { HexColorPicker, HexColorInput } from "react-colorful";
import './chooseDS.css';
import { designSystems, getAvailableDesignSystems } from "../../../../../utils/designSystems/loader/designSystemLoader";



function ChooseDS ({setSelectedOption}){
    const designSystems = getAvailableDesignSystems();
    const [selectedSystem, setSelectedSystem] = useState(designSystems[0]?.name);

    useEffect(() => {
        // Set the first system's ID as default
        if (designSystems[0]) {
            setSelectedOption(designSystems[0].id);
        }
    }, []);

    const handleClick = (system) => {
        setSelectedSystem(system.name);
        setSelectedOption(system.id);
    }

    return(     
            <div>
                <Popover.Root>
                    <Popover.Trigger asChild>
                        <div className="choose-ds-trigger">
                            <p>{selectedSystem} <CaretDownIcon/></p>
                        </div>
                    </Popover.Trigger>
                    <Popover.Portal>
                    <Popover.Content sideOffset={10}>
                        <div className="choose-ds-container">
                            {
                                designSystems.map((system) => (
                                    <div key={system.id} className="choose-ds-item" onClick={() => handleClick(system)}>
                                        <p>{system.name}</p>
                                    </div>
                                ))
                            }
                        </div>
                    </Popover.Content>
                    </Popover.Portal>
                </Popover.Root>
            </div>
    )
}

export default ChooseDS