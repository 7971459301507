import React, {useRef, useEffect, useState} from "react";
import { Html } from 'react-konva-utils';
import { Group, Rect, Transformer } from 'react-konva';
import { useMeasure } from "react-use";
import { useEditorContext } from "../../../../context/editor/editorcontext";
import { getComponent, getDesignSystemScope } from "../../../../utils/designSystems/loader/designSystemLoader";
import { LiveProvider, LivePreview, LiveError } from 'react-live';
import MeasurementLine from "../../utils/measurementline";
import { useMeasurementTool } from '../../utils/useMeasurementTool';



function CoreDesignSystem({
    stageRef,
    keyVal,
    shapeProps,
    setDomensions,
    isSelected,
    setSelectedId,
    elWidth,
    elHeight,
    onSelect,
    onDragMove,
    onDragEnd,
    onTransform,
    onTransformEnd
}) {
    const shapeRef = useRef();
    const trRef = useRef();
    const editorContext = useEditorContext();
    const [domDimensions, setDomDimensions] = useState({width: 50, height: 50});
    const [dimensionRef, { width, height }] = useMeasure();
    const [loaded, setLoaded] = useState(false);
    const [loadedWidth, setLoadedWidth] = useState(null);
    const [loadedHeight, setLoadedHeight] = useState(null);
    const [interactionMode, setInteractionMode] = useState(false);
    const [hoverState, setHoverState] = useState(false);
    const [component, setComponent] = useState(null);
    const [finalProps, setFinalProps] = useState(null);

    const measurementLine = useMeasurementTool(stageRef, isSelected, {
        xpos: shapeProps.xpos,
        ypos: shapeProps.ypos,
        width: elWidth,
        height: elHeight,
        grouped: shapeProps.grouped,
        grouped_by: shapeProps.grouped_by
    });

    // Get the appropriate scope based on the design system
    const designSystemScope = getDesignSystemScope(shapeProps.systemId);
    
    const editorScope = {
        ...designSystemScope,
        Rivveo: {
            triggerPrototype: () => {} // Empty function for editor
        }
    };

    useEffect(() => {
        setDomDimensions({width, height});
        setDomensions({width, height});
    }, [width, height]);

    useEffect(() => {
        if(loaded == false) {
            setLoadedWidth(shapeProps.width);
            setLoadedHeight(shapeProps.height);
            setLoaded(true);
        }
    }, []);

    useEffect(() => {
        if(editorContext.activeElement?.id == shapeProps.id) {
            setSelectedId(shapeProps.id);
            setInteractionMode(editorContext.activeElement.interaction_mode);
        }
    }, [editorContext.activeElement]);

    // useEffect(() => {
    //     console.log('Looking up component:', shapeProps.systemId, shapeProps.component_name);
    //     const componentInfo = getComponent(shapeProps.systemId, shapeProps.component_name);
    //     console.log('Component info:', componentInfo);
    //     if (componentInfo && componentInfo.component) {
    //         console.log('Setting component:', componentInfo);
    //         setComponent(componentInfo);
    //     }
    // }, [shapeProps.systemId, shapeProps.component_name]);

    useEffect(() => {
        if (isSelected) {
            trRef.current.nodes([shapeRef.current]);
            trRef.current.getLayer().batchDraw();
        }
    }, [isSelected]);

    useEffect(() => {
        const handleKeyDown = (event) => {
            if(isSelected) {
                if ((event.metaKey || event.ctrlKey) && event.code === 'Backspace') {
                    const props = {
                        id: shapeProps.id,
                        type: 'designSystem',
                        grouped: shapeProps.grouped,
                        grouped_by: shapeProps.grouped_by,
                    };
                    editorContext.deleteElement(props);
                }
            }
        };
        window.addEventListener('keydown', handleKeyDown);
        return () => window.removeEventListener('keydown', handleKeyDown);
    }, [isSelected]);

    useEffect(() => {
        if(!isSelected) return;
        const konvaContent = stageRef.content;
        const transformerElement = trRef.current.getNode().getCanvas()._canvas;
        
        transformerElement.style.position = 'absolute';
        transformerElement.style.top = '0';
        transformerElement.style.left = '0';
        transformerElement.style.zIndex = '9999';
        transformerElement.style.pointerEvents = 'none';
        
        konvaContent.appendChild(transformerElement);
    }, [isSelected]);

    // useEffect(() => {  // Changed from useState to useEffect
    //     const normalizeProps = (props) => {
    //         if (!props) return {};
            
    //         const normalized = {};
    //         Object.entries(props).forEach(([key, value]) => {
    //             if (value && typeof value === 'object' && value.type === 'select') {
    //                 normalized[key] = value.default;
    //             } else if (value && typeof value === 'object' && 'default' in value) {
    //                 normalized[key] = value.default;
    //             } else {
    //                 normalized[key] = value;
    //             }
    //         });
            
    //         return normalized;
    //     };
    
    //     const normalizedProps = normalizeProps(shapeProps.props);
        
    //     setFinalProps({
    //         ...component?.previewProps,
    //         ...shapeProps.defaultProps,
    //         ...normalizedProps,
    //         style:{
    //             width: '100%',
    //             height: '100%'
    //         }
    //     });
    // }, [shapeProps, component]);
    
    
    return (
        <>
            <Group
                key={keyVal}
                draggable={true}
                onDragMove={onDragMove}
                onDragEnd={onDragEnd}
                x={shapeProps.xpos}
                y={shapeProps.ypos}
                width={parseFloat(elWidth)}
                height={parseFloat(elHeight)}
            >
                <Html divProps={{ style: { pointerEvents: interactionMode && isSelected ? "auto" : "none" }}}>
                    <div ref={dimensionRef} style={{
                        width: parseFloat(elWidth),
                        height: parseFloat(elHeight),
                        padding: 0,
                        margin: 0,
                    }}>
                        <LiveProvider 
                            code={shapeProps.code} 
                            scope={editorScope}
                            noInline={true}
                            transformCode={(code) => {
                                const componentId = shapeProps.id ? shapeProps.id.slice(0, 8) : Math.random().toString(36).slice(2, 8);
                                const functionName = `${shapeProps.component_name}_${componentId}`;
                                return `
function ${functionName}() {
  try {
    ${code.replace(/^\(\)\s*=>\s*{/, '').replace(/}$/, '')}
  } catch (error) {
    return null;
  }
}

try {
  render(<${functionName} />);
} catch (error) {
  render(null);
}`;
                            }}
                        >
                                <LivePreview />
                                <LiveError 
                                    style={{
                                        display: 'none'
                                    }}
                                />
                        </LiveProvider>
                    </div>
                </Html>
                <Rect
                    onClick={onSelect}
                    onTap={onSelect}
                    width={domDimensions.width}
                    height={domDimensions.height}
                    fill={'transparent'}
                    ref={shapeRef}
                    id={shapeProps.id}
                    name="shape"
                />
            </Group>

            {measurementLine && (
                <MeasurementLine
                    {...measurementLine}
                />
            )}

            {isSelected && (
                <Transformer
                    ref={trRef}
                    flipEnabled={false}
                    anchorCornerRadius={100}
                    rotateEnabled={false}
                    enabledAnchors={["bottom-right", "bottom-center", "middle-right"]}
                    anchorSize={10}
                    padding={8}
                    onTransform={onTransform}
                    onTransformEnd={onTransformEnd}
                    rotationSnaps={[0, 90, 180, 270]}
                    rotationSnapTolerance={10}
                    keepRatio={true}
                />
            )}
        </>
    );
}

export default CoreDesignSystem;