import { addons } from '@storybook/manager-api';
import { Preview } from '@storybook/preview-api';
import { SET_STORIES, STORY_RENDERED, UPDATE_STORY_ARGS } from '@storybook/core-events';
import { supabase } from '../../../supabase/supabaseclient.js';

class StorybookService {
  constructor() {
    this.storybookUrl = '';
    this.preview = null;
    this.stories = new Map();
    this.connected = false;
  }

  setCredentials(url) {
    this.storybookUrl = this.normalizeUrl(url);
  }

  normalizeUrl(url) {
    return url.replace(/\/$/, '');
  }

  async proxyFetch() {
    const { data, error } = await supabase.functions.invoke('get-storybook-url', {
      body: {
        url: this.storybookUrl,
      }
    });

    if (error) {
      throw error;
    }

    if(data){
      return data;
    }

  }

  async connect(url = null) {
    if (url) {
      this.setCredentials(url);
    }

    if (!this.storybookUrl) {
      throw new Error('Storybook URL not set');
    }

    try {
      // Fetch stories through proxy
      const storiesData = await this.proxyFetch('/stories.json');
      this.stories = this.processStories(storiesData);

      // Setup preview API
      this.preview = new Preview();
      
      // Initialize preview with project annotations
      await this.preview.initialize({
        importFn: async (path) => {
          return await this.proxyFetch(path);
        },
        getProjectAnnotations: async () => {
          return await this.proxyFetch('/preview.js');
        }
      });

      this.connected = true;
      return true;
    } catch (error) {
      console.error('Failed to connect to Storybook:', error);
      throw error;
    }
  }

  processStories(data) {
    const stories = new Map();
    
    Object.entries(data.stories || {}).forEach(([id, story]) => {
      if (story.parameters?.docsOnly) return;

      stories.set(id, {
        id,
        title: story.title,
        name: story.name,
        importPath: story.importPath,
        type: 'storybook',
        width: 200,
        height: 50,
        xpos: 0,
        ypos: 0,
        scaleX: 1,
        scaleY: 1,
        in_screen: true,
        zIndex: 0,
        args: story.initialArgs || {},
        parameters: story.parameters || {}
      });
    });

    return stories;
  }

  getStories() {
    return Array.from(this.stories.values());
  }

  async renderStory(storyId, args = {}) {
    if (!this.preview) {
      throw new Error('Preview API not initialized');
    }

    const story = this.stories.get(storyId);
    if (!story) {
      throw new Error(`Story ${storyId} not found`);
    }

    try {
      const rendered = await this.preview.renderToDOM({
        id: storyId,
        args: { ...story.args, ...args }
      });

      return rendered;
    } catch (error) {
      console.error(`Failed to render story ${storyId}:`, error);
      throw error;
    }
  }

  updateStoryArgs(storyId, newArgs) {
    if (!this.preview) return;
    
    this.preview.setStoryContext(storyId, {
      args: newArgs
    });
  }

  disconnect() {
    if (this.preview) {
      this.preview.clean();
      this.preview = null;
    }
    this.stories.clear();
    this.connected = false;
  }
}

export const storybookService = new StorybookService();