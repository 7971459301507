import React, { useState, useEffect, useRef, Fragment } from 'react';
import { supabase } from '../../../supabase/supabaseclient';
import { useNavigate, Link } from 'react-router-dom';
import { useAuthContext } from '../../../context/auth/authcontext';
import './selectplan.css'
import { TiTick } from 'react-icons/ti'

function SelectPlan(){

    const navigate = useNavigate();
    const authContext = useAuthContext();

    const handlePlan = (plan) => {
        if(plan == 'Pro'){
            navigate('/signup/your-team/true')
        }else if(plan == 'Enterprise'){
            window.open('https://www.rivveo.com/contact', '_blank');
        } 
    }

    return (
        <div className='selectplan-main'>
            <div className='selectplan-header-container'>
                <h1 className="selectplan-header">Rivveo <span>BETA</span></h1>

            </div>
            <div className='selectplan-box'>
                
                <div className='signup-header-container'>
                    <h1 className='selectplan-box-header'>Choose plan</h1>
                    <div className='signup-progress'>
                        <p>2/5</p>
                    </div>
                </div>
                

                <div className='selectplan-options'>

                    <div className='selectplan-option-container'>
                        <h1>Pro</h1>
                        <button className='selectplan-cta' onClick={()=>handlePlan('Pro')}>
                            Start your free 30 day trial
                        </button>
                        <div className='selectplan-option-pricing'>
                            <h2>£19/user/mo</h2>
                        </div>
                        <div className='selectplan-option-features'>
                            <h4>What's included</h4>
                            <div className='selectplan-features'>
                                <TiTick color='#D138E5'/>
                                <p>Unlimited Files</p>
                            </div>
                            <div className='selectplan-features'>
                                <TiTick color='#D138E5'/>
                                <p>Up to 35 Users</p>
                            </div>
                            <div className='selectplan-features'>
                                <TiTick color='#D138E5'/>
                                <p>No-code elements</p>
                            </div>
                            <div className='selectplan-features'>
                                <TiTick color='#D138E5'/>
                                <p>Prototyping capabilities</p>
                            </div>
                            <div className='selectplan-features'>
                                <TiTick color='#D138E5'/>
                                <p>Integrations with MUI & AntD</p>
                            </div>
                            <div className='selectplan-features'>
                                <TiTick color='#D138E5'/>
                                <p>Custom code components</p>
                            </div>
                            <div className='selectplan-features'>
                                <TiTick color='#D138E5'/>
                                <p>Interaction mode</p>
                            </div>
                            <div className='selectplan-features'>
                                <TiTick color='#D138E5'/>
                                <p>Automatic code generation</p>
                            </div>
                        </div>
                    </div>

                    <div className='selectplan-option-container'>
                        <h1>Enterprise</h1>
                        <button className='selectplan-cta' onClick={()=>handlePlan('Enterprise')}>
                            Contact us
                        </button>
                        <div className='selectplan-option-pricing'>
                            <h2>£46/user/mo</h2>
                        </div>
                        <div className='selectplan-option-features'>
                            <h4>What's included</h4>
                            <div className='selectplan-features'>
                                <TiTick color='#D138E5'/>
                                <p>Everything from Pro</p>
                            </div>
                            <div className='selectplan-features'>
                                <TiTick color='#D138E5'/>
                                <p>SSO with SAML 2.0</p>
                            </div>
                            <div className='selectplan-features'>
                                <TiTick color='#D138E5'/>
                                <p>Multi-factor authentication</p>
                            </div>
                            <div className='selectplan-features'>
                                <TiTick color='#D138E5'/>
                                <p>Personalised onboarding</p>
                            </div>
                            <div className='selectplan-features'>
                                <TiTick color='#D138E5'/>
                                <p>Premium support 24/7</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default SelectPlan;