import React, { useState, useEffect, useRef, Fragment } from 'react';
import * as Dialog from "@radix-ui/react-dialog";
import { supabase } from '../../supabase/supabaseclient';
import { useAuthContext } from '../../context/auth/authcontext';
import { PlusIcon } from '@radix-ui/react-icons';
import { Link, useNavigate} from 'react-router-dom';
import './newFileModal.css';
import { Grid } from '@radix-ui/themes';
import Editor from '../../pages/editor/editor';
import { useEditorContext } from '../../context/editor/editorcontext';


function NewFileModal(){

    const [screenFilter, setScreenFilter] = useState('Mobile');
    const [fileTitle, setFileTitle] = useState('Untitled');
    const [selection, setSelection] = useState(null);
    const editorContext = useEditorContext();
    const authContext = useAuthContext();
    const navigate = useNavigate();

    const screenList = [
        {
            Name: 'Macbook Air',
            Type: 'Desktop'
        },
        {
            Name: 'Macbook Pro 14"',
            Type: 'Desktop'
        },
        {
            Name: 'Macbook Pro 16"',
            Type: 'Desktop'
        },
        {
            Name: 'Small Desktop',
            Type: 'Desktop'
        },
        {
            Name: 'Medium Desktop',
            Type: 'Desktop'
        },
        {
            Name: 'Large Desktop',
            Type: 'Desktop'
        },
        {
            Name: 'iPhone 16',
            Type: 'Mobile'
        },
        {
            Name: 'iPhone 16 Plus',
            Type: 'Mobile'
        },
        {
            Name: 'iPhone 16 Pro',
            Type: 'Mobile'
        },
        {
            Name: 'iPhone 16 Pro Max',
            Type: 'Mobile'
        },
        {
            Name: 'iPhone SE',
            Type: 'Mobile'
        },
        {
            Name: 'Samsung Galaxy S24',
            Type: 'Mobile'
        },
        {
            Name: 'iPad Pro 12.9"',
            Type: 'Tablet'
        },
        {
            Name: 'iPad Pro 11"',
            Type: 'Tablet'
        },
        {
            Name: 'iPad Mini',
            Type: 'Tablet'
        },
        {
            Name: 'Surface Pro 8',
            Type: 'Tablet'
        },
    ];

    const handleNewDesign = async(filename, screen, type) => {
        const {data, error}  = await supabase
        .from('Files')
        .insert({
            file_name: filename, 
            screen_size: screen,
            team: authContext.team[0]?.Teams?.id,
            type: type,
        })
        .select();
        
        if(data){
            navigate(`/design/${data[0].id}`);
            setSelection(null);
            // this is a hack to automatically add a screen to new files    
        }

        if(error){
            console.log(error)
        }
    }

    const ScreenItem = ({item}) => (
        <div 
            className={selection == item.Name ? 'ScreenItemSelected' : 'ScreenItem'}
            onClick={()=> selection != item.Name ? setSelection(item.Name) : setSelection(null)}
        >
            <p>{item.Name}</p>
        </div>
    )

    return(
    <Dialog.Root>
		<Dialog.Trigger asChild>
			<button className="NewFileModalButton violet">
            New File <PlusIcon width={25} height={25}/> 
            </button>
		</Dialog.Trigger>
		<Dialog.Portal>
			<Dialog.Overlay className="NewFileModalOverlay" />
			<Dialog.Content className="NewFileModalContent">
                <div className='NewFileModalMain'>
                    <div className='NewFileModalSidePanel'>
                        <div className='NewFileModalFilters'>
                            <button className={screenFilter == 'Mobile' ? 'NewFileModalFilterButtonSelected' : 'NewFileModalFilterButton' } onClick={()=>setScreenFilter('Mobile')}>Mobile</button>
                            <button className={screenFilter == 'Desktop' ? 'NewFileModalFilterButtonSelected' : 'NewFileModalFilterButton' } onClick={()=>setScreenFilter('Desktop')}>Desktop</button>
                            <button className={screenFilter == 'Tablet' ? 'NewFileModalFilterButtonSelected' : 'NewFileModalFilterButton' } onClick={()=>setScreenFilter('Tablet')}>Tablet</button>
                        </div>
                    </div>
                    <div className='NewFileModalMainContent'>
                            <div className='NewFileModalTitle'>
                                <label className="Label" htmlFor="name">
                                    New file name
                                </label>
                                <input className="Input" id="name" value={fileTitle} onChange={(e)=>setFileTitle(e.target.value)} />
                            </div>
                           
                            <Grid className='NewFileGrid' columns="3" gap="30" width="auto">
                                {
                                    screenList.map(item =>{
                                        return(item.Type === screenFilter ? <ScreenItem item={item}/> :null)
                                    })
                                }
                            </Grid>
                    </div>
                </div>
                <div className='NewFileModalButtonContainer'>
                    <button className={selection && fileTitle ? 'CreateFileButtonSelected' : 'CreateFileButton' } onClick={()=> selection && fileTitle ? handleNewDesign(fileTitle, selection, screenFilter):null}>
                        Create File
                    </button>
                </div> 
			</Dialog.Content>
		</Dialog.Portal>
	</Dialog.Root>
    )
}

export default NewFileModal;