import React, {useEffect, useState} from 'react';
import './leftsp.css'
import * as Tabs from '@radix-ui/react-tabs';
import { useEditorContext } from '../../../context/editor/editorcontext';
import ScreenSelector from './screenSelector/screenSelector';
import Layer from './layer/layer';
import { DndContext, closestCenter, KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { arrayMove, SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { restrictToVerticalAxis, restrictToFirstScrollableAncestor } from '@dnd-kit/modifiers';
import ResizableCodePanel from '../../codeEditor/resizeableCodePanel';

function Leftsp(){

    const editorContext = useEditorContext();
    const [selectedScreen, setSelectedScreen] = useState(null);

    const pointerSensor = useSensor(PointerSensor, {
      activationConstraint: {
        distance: 10
      }
    })
    const sensors = useSensors(
      pointerSensor,
      useSensor(KeyboardSensor, {
        coordinateGetter: sortableKeyboardCoordinates,
      })
    );

    function handleDragEnd(event) {
        const {active, over} = event;
        
        if (active.id !== over.id) {

          const oldIndex = editorContext.element.findIndex((item) => item.id === active.id);
          const newIndex = editorContext.element.findIndex((item) => item.id === over.id);

          // update the active element layer DB
          // editorContext.updateElementZindex(activeProps);
          // update the active element layer locally
          editorContext.setElement((layer) => {
            const oldIndex = layer.findIndex((item) => item.id === active.id);
            const newIndex = layer.findIndex((item) => item.id === over.id);
            return arrayMove(layer, oldIndex, newIndex);
          });

          if(newIndex > oldIndex){
            // index has increased
            // so decrease values in range
            for(let i = oldIndex; i <= newIndex; i++){
              if(editorContext.element[i].id !== editorContext.element[oldIndex].id){
                const inRangeElements = {
                  id: editorContext.element[i].id,
                  type: editorContext.element[i].type,
                  zIndex: i - 1,
                }
                //console.log('layer in range', editorContext.element[i].name, editorContext.element[i].zIndex - 1)
                editorContext.updateElementZindex(inRangeElements);
              }else if(editorContext.element[i].id == editorContext.element[oldIndex].id){
                //this is the layer that has been moved
                const activeLayer = {
                  id: editorContext.element[i].id,
                  type: editorContext.element[i].type,
                  zIndex: newIndex,
                }
               // console.log('This is active layer index', newIndex,);
                editorContext.updateElementZindex(activeLayer);
              }
            }
          }else if(newIndex < oldIndex){
            //index has decreased 
            //so increase values in range
            for(let i = newIndex; i <= oldIndex; i++){
              if(editorContext.element[i].id !== editorContext.element[oldIndex].id){
                // we don't want to change the layer being moved
                // update layers in range locally and db
                // db update
                const inRangeElements = {
                  id: editorContext.element[i].id,
                  type: editorContext.element[i].type,
                  zIndex: i + 1,
                }
                editorContext.updateElementZindex(inRangeElements);
              }else if(editorContext.element[i].id == editorContext.element[oldIndex].id){
                //this is the layer that has been moved
                const activeLayer = {
                  id: editorContext.element[i].id,
                  type: editorContext.element[i].type,
                  zIndex: newIndex,
                }
                editorContext.updateElementZindex(activeLayer);

              }
            }
          }

        }
      }

      function handleChildDragEnd(event) {
        const {active, over} = event;
        
        if (active.id !== over.id) {
            // const oldIndex = layer.findIndex((item) => item.id === active.id);
            // const newIndex = layer.findIndex((item) => item.id === over.id);
            //return arrayMove(layer, oldIndex, newIndex);
            editorContext.setElement(()=>{
             // const oldIndex = element.children.findIndex((item) => item.id === active.id);
             // const newIndex = element.children.findIndex((item) => item.id === over.id);
             const childEl = editorContext.element.filter((i)=>i.id == active.id);
             console.log(childEl);
             const containerId = childEl[0].grouped_by;

             const newArr = editorContext.element.map((el)=>{
                if(el.id !== containerId){return{...el}};

                console.log('child drag end', containerId,  el.id);
                // in the correct container
                const oldIndex = el.children.findIndex((item) => item.id === active.id);
                const newIndex = el.children.findIndex((item) => item.id === over.id);
                const newChildren = arrayMove(el.children, oldIndex, newIndex); 
                editorContext.updateChildZindexDb(newChildren, containerId)


                console.log({
                  ...el,
                  children: newChildren,
                });

                return{
                  ...el,
                  children: newChildren,
                }

              }) 
              console.log('newArr', newArr);
              return newArr;

            })
        }
      }

    return (
      <>
      {
        editorContext.loaded?
      
      <div className='lspcontainer'>

        <div className='lspscroll'>

        <div className='layers'>  

          <ScreenSelector setSelectedScreen={setSelectedScreen}/>
          <DndContext 
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
            modifiers={[restrictToVerticalAxis, restrictToFirstScrollableAncestor]}
          >
            <SortableContext 
              items={editorContext.element}
              strategy={verticalListSortingStrategy}
            >
              <div className='layer-title-container'>
                <p className='layer-title'>Layers</p>
              </div>
              <div className='layer-scroll-area'>
                  {
                      editorContext.element.map((element, i)=>(
                          element.screen == selectedScreen && !element.grouped &&
                          <>
                            <Layer element={element} key={element.id} id={element.id} type={'parent'} />
                            {
                              element.type == 'container' &&

                              <DndContext 
                                sensors={sensors}
                                collisionDetection={closestCenter}
                                onDragEnd={handleChildDragEnd}
                                modifiers={[restrictToVerticalAxis, restrictToFirstScrollableAncestor]}
                              >

                                <SortableContext 
                                  items={element.children}
                                  strategy={verticalListSortingStrategy}
                                >
                                  <div>
                                    {
                                      element.type == 'container' && 
                                      element.children.map((child)=>(
                                        <Layer element={child} key={child.id} id={child.id} type={'child'}/>
                                      ))
                                    }
                                  </div>
                                </SortableContext>
                              </DndContext>
                            }
                          </>
        
                      ))
                  }
              </div>
            </SortableContext>
          </DndContext>
        </div>

        {editorContext.activeElement && editorContext.activeElement.type !== 'designSystem' && (
          <div className="code-panel-wrapper">
            <ResizableCodePanel />
          </div>
        )}
      </div>  
      </div>
      :null
      }
      </>
  
      )
}

export default Leftsp;