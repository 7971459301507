import React, { useRef, useEffect, useState, memo, useMemo } from "react";
import { Html } from 'react-konva-utils';
import { Group, Rect } from 'react-konva';
import { useMeasure } from "react-use";
import { usePrototypeContext } from "../../../../../context/prototype/prototypecontext";
import { getDesignSystemScope } from "../../../../../utils/designSystems/loader/designSystemLoader";
import { RepositionElements, adjustElementPosition } from "../../../utils/repositionElements";
import { LiveProvider, LivePreview } from 'react-live';
import Konva from "konva";
import executeGlobalAnim from "../../../utils/executeGlobalAnim";

function CorePlayerDesignSystem({element, screenWidth, screenHeight, canvasX, canvasY, scale, stageY}) {
    const prototypeContext = usePrototypeContext();
    const [domDimensions, setDomDimensions] = useState({width: 50, height: 50});
    const [dimensionRef, { width, height }] = useMeasure();
    const [activateElement, setActivateElement] = useState(false);
    const [hoverState, setHoverState] = useState(false);
    const animRef = useRef();
    
    const [translatedX, setTranslatedX] = useState(0);
    const [translatedY, setTranslatedY] = useState(0);
    const [elementX, setElementX] = useState(0);
    const [elementY, setElementY] = useState(0);

    // Get the appropriate scope based on the design system
    const designSystemScope = getDesignSystemScope(element.systemId);

    // Create a simple global trigger function for users
    const enhanceComponentScope = {
        ...designSystemScope,
        Rivveo: {
            triggerPrototype: (interactionId) => handlePrototype('On click', element, interactionId)
        }
    };

    useEffect(() => {
        console.log('scale', scale);
    }, [scale,]);

    const runAnim = (xval, animTrigger, anim) => {
        const diff = Math.abs(translatedX - element.xpos);
        const finalExitPos = translatedX + element.xpos + screenWidth + diff;
        
        new Konva.Tween({
            node: animRef.current,
            easing: Konva.Easings.Linear,
            duration: 0.2,
            x: animTrigger.exitScreen == element.screen && anim == 'TransitionOutLeftRight' 
                ? -element.width
                : xval,
            onFinish: () => {
                if(animTrigger.exitScreen == element.screen && anim == 'TransitionOutLeftRight'){
                    setElementX(finalExitPos);
                } else {
                    setElementX(xval);
                }
            }
        }).play();
    }

    useEffect(() => {
        const elementScreen = prototypeContext.screens.find((i) => i.id == element.screen);
        const screen1 = {xpos: elementScreen?.xpos, ypos: elementScreen?.ypos} 
        const screen2 = {xpos: canvasX - screenWidth/2, ypos: canvasY - screenHeight/2}
        const xy = RepositionElements(screen1, screen2);
        const newPos = adjustElementPosition(element, prototypeContext.currentRenderedScreen, xy, screenWidth)

        setTranslatedX(xy.xpos);
        setTranslatedY(xy.ypos);
        setElementX(newPos);
        setElementY(xy.ypos + element.ypos);
    }, []);

    useEffect(() => {
        setDomDimensions({
            width: width,
            height: height
        });
    }, [width, height]);

    useEffect(() => {
        if(prototypeContext.animTrigger.type == 'global' && 
           prototypeContext.animTrigger.exitScreen == element.screen && 
           prototypeContext.animTrigger.sequence === 'exit') {
            const globalAnim = executeGlobalAnim(prototypeContext.animTrigger.exitScreenAnimation, element, translatedX, prototypeContext.animTrigger);
            runAnim(globalAnim, prototypeContext.animTrigger, prototypeContext.animTrigger.exitScreenAnimation);
            setActivateElement(false);
        } else if(prototypeContext.animTrigger.type == 'global' && 
                 prototypeContext.animTrigger.entranceScreen == element.screen && 
                 prototypeContext.animTrigger.sequence === 'entrance') {
            const globalAnim = executeGlobalAnim(prototypeContext.animTrigger.entranceScreenAnimation, element, translatedX, prototypeContext.animTrigger);
            runAnim(globalAnim, prototypeContext.animTrigger, prototypeContext.animTrigger.entranceScreenAnimation);
            setActivateElement(false);

            if(prototypeContext.currentRenderedScreen.id != element.screen) {
                const screen = prototypeContext.screens.filter((i)=>i.id == element.screen);
                prototypeContext.setCurrentScreen(screen[0]);
                prototypeContext.setCurrentRenderedElements(element);
            }
        } else if(prototypeContext.animTrigger.type == 'individual' && 
                 prototypeContext.animTrigger.originElementId == element.id) {
            console.log('design system individual animation', element.id);
        }
    }, [prototypeContext.animTrigger]);

    const handlePrototype = (event, element, specificId = null) => {
        console.log('handlePrototype called with:', event, specificId);
        const res = prototypeContext.triggerPrototype(event, element, specificId);
        
        if(res == false && event == 'On click') {
            setActivateElement(true);
        } else if(res == true) {
            setActivateElement(true);
        } else {
            setActivateElement(false);
        }
    }

    return (
        <Group 
            key={element.id}
            ref={animRef}
            x={element.grouped ? element.xpos : elementX}
            y={element.grouped ? element.ypos : element.scroll_behaviour === 'fixed' ? elementY - (stageY/scale) : elementY}
            width={element.width}
            height={element.height}
        >
            <Html divProps={{
                style: {
                    pointerEvents: 'auto',
                    width: `${element.width}px`,
                    height: `${element.height}px`,
                }
            }}>
                <div 
                    ref={dimensionRef}
                    style={{
                        width: parseFloat(element.width),
                        height: parseFloat(element.height),
                        padding: 0,
                        margin: 0,
                    }}
                >
                    <LiveProvider 
                        code={element.code}
                        scope={enhanceComponentScope}
                        noError={true}
                        noInline={true}
                        transformCode={(code) => {
                            const componentId = element.id ? element.id.slice(0, 8) : Math.random().toString(36).slice(2, 8);
                            const functionName = `${element.component_name}_${componentId}`;
                            return `
function ${functionName}() {
  try {
    ${code.replace(/^\(\)\s*=>\s*{/, '').replace(/}$/, '')}
  } catch (error) {
    return null;
  }
}

try {
  render(<${functionName} />);
} catch (error) {
  render(null);
}`;
                        }}
                    >
                        <LivePreview />
                    </LiveProvider>
                </div>
            </Html>
            <Rect
                width={element.width}
                height={element.height}
                fill={'transparent'}
            />
        </Group>
    );
}

export default memo(CorePlayerDesignSystem);