import React, { useState, useEffect, useRef, Fragment } from 'react';
import { GearIcon, PlusIcon, Cross2Icon } from '@radix-ui/react-icons';
import { supabase } from '../../../supabase/supabaseclient';
import * as DropdownMenu from "@radix-ui/react-dropdown-menu"
import './invitemembers.css';
import { useAuthContext } from '../../../context/auth/authcontext';

function InviteMembers (){

    const [emails, setEmails] = useState([]);
    const emailRef = useRef();
    const authContext = useAuthContext();
    const [inviteButton, setInviteButton] = useState('Invite members');

    const handleNewEmail = (event) => {
        if(event.key === 'Enter'){
            setEmails((emails)=>[...emails, event.target.value]);
            emailRef.current.value='';
        }
    }

    const deleteEmail = (email) => {
        const item = emails.filter((i)=> i === email);
        if(item){
            const newArr = emails.filter((i)=> i !== email);
            setEmails(newArr);
        }
    }

    const sendInvites = () => { 

        if(emails.length === 0) return;
        // loop over invites 
            emails.map(async(email)=>{
                console.log('inviting', email)
                const { data, error } = await supabase.functions.invoke('invite-members', {
                    body: {
                        email: email,
                        team_id: authContext.team[0]?.Teams?.id,
                    }
                })

                if(data){
                    console.log(data)
                }

                if(error){
                    console.log(error);
                }
                
            })

            setInviteButton('Invites sent');
            setEmails([]);
            setTimeout(()=>{
                setInviteButton('Invite members'); 
            },1000)
    }

    return(
        <DropdownMenu.Root modal={false}>
            <DropdownMenu.Trigger asChild>
                <div className='invite-member-button trigger'>
                Invite members <PlusIcon width={20} height={20}/>
                    
                </div>    
            </DropdownMenu.Trigger>
            <DropdownMenu.Portal>
                <DropdownMenu.Content className="invite-members-dropdown-menu-content">
                    <div className='invite-members-dropdown-header'>
                        <h4>Invite team members</h4>
                    </div>
                    <div className='invite-members-dropdown-input'>
                        <input ref={emailRef} placeholder='Invite team member by email' onKeyDown={(e)=>handleNewEmail(e)}/>
                        <div className='invite-member-email-chips-container'>
                            {
                                emails.map((email)=>(
                                    <div className='invite-member-email-chips' onClick={(e)=>deleteEmail(email)}>
                                        <p>{email}</p>
                                        <Cross2Icon/>
                                    </div>
                                ))
                            }
                        </div>
                        <button onClick={()=>sendInvites()} style={{background: emails.length == 0 ? '#302C49' : '#342F5B', color: emails.length == 0 ? '#676572' : '#fff'}}>
                            {inviteButton}
                        </button>
                    </div>
                </DropdownMenu.Content>
            </DropdownMenu.Portal>
        </DropdownMenu.Root>


    )
}

export default InviteMembers;