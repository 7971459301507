import React, { memo } from "react";
import { usePrototypeContext } from "../../../../context/prototype/prototypecontext";
import CorePlayerDesignSystem from "./core/coreplayerdesignsystem";

function PlayerDesignSystem({element, screenWidth, screenHeight, canvasX, canvasY, scale, stageY}) {
    const prototypeContext = usePrototypeContext();

    return (
        <> 
            {element.in_screen && 
                <CorePlayerDesignSystem
                    key={element.id}
                    element={element}
                    screenWidth={screenWidth}
                    screenHeight={screenHeight}
                    canvasX={canvasX}
                    canvasY={canvasY}
                    scale={scale}
                    stageY={stageY}
                />
            }
        </>
    );
}

export default memo(PlayerDesignSystem); 