import React, { useState } from 'react';
import { useEditorContext } from '../../../../../context/editor/editorcontext';
import '../elementpanels/elementpanel.css';
import { SizeIcon } from '@radix-ui/react-icons';
import { LiveProvider, LiveEditor, LiveError } from 'react-live';
import { designSystemScope } from '../../../../../utils/designSystems/loader/designSystemLoader';
import { Highlight, themes } from "prism-react-renderer";
import './autodesignpanel.css';
import ExpandedCodeView from '../../../expandedcodeview/expandedcodeview';



function AutoDesignSystemPanel({ item }) {
    const editorContext = useEditorContext();
    const [localCode, setLocalCode] = useState(item.code);
    
    // // Debug logs
    // console.log('Item received:', item);
    // console.log('Item code:', item.code);
    // console.log('Local code:', localCode);
    React.useEffect(() => {
        const trimmedCode = item.code
            .split('\n')
            .map(line => line.trim())
            .join('\n');
        setLocalCode(trimmedCode);
    }, [item]);

    const handleCodeChange = (newCode) => {     
        setLocalCode(newCode);  // Update local state immediately
        editorContext.updateElementCustomPanel({
            id: item.id,
            type: item.type,
            grouped: item.grouped,
            grouped_by: item.grouped_by,
            code: newCode
        });
    };

    return (
        <div className="element-panel-container">
            <div className="element-panel-title">
                <p>{item.name || item.type.split('-')[1]}</p>
            </div>
            <div className="element-panel-code-header">
                <p>Code</p>
                <button>
                    <ExpandedCodeView item={item} code={localCode} scope={designSystemScope} theme={themes.palenight} language="jsx"/>
                </button>
            </div>
            <div className="element-panel-content">
                <LiveProvider 
                    code={localCode}  // Use local state instead of item.code
                    scope={designSystemScope}
                    theme={themes.palenight}
                    language="jsx"
                >
                    <div style={{
                        position: 'relative',
                        marginBottom: '16px',
                        maxHeight: '400px',  // Add max height
                        overflow: 'auto',      // Enable scrolling
                    }}>
                        <LiveEditor 
                            onChange={(newCode) => {
                                // const trimmedCode = newCode
                                //     .split('\n')
                                //     .map(line => line.trim())
                                //     .join('\n');
                                handleCodeChange(newCode);
                            }}
                            style={{
                                fontSize: '14px',
                                fontFamily: 'ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, monospace',
                                backgroundColor: '#292D3E',
                                borderRadius: '10px',
                                lineHeight: '1.5',
                                minHeight: '100px',
                                whiteSpace: 'nowrap',
                                overflowX: 'auto',
                                width: '100%',
                                boxSizing: 'border-box',
                            }}
                            onKeyDown={(e) => {
                                // Stop propagation for all keyboard events
                                e.stopPropagation();
                                // Prevent default behavior for arrow keys
                                if (['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'].includes(e.key)) {
                                    e.stopPropagation();
                                }
                            }}
                            onClick={(e) => {
                                // Ensure clicks are also captured
                                e.stopPropagation();
                            }}
                        />
                    </div>
                    <LiveError 
                        style={{
                            color: '#ff5555',
                            margin: '8px 0',
                            padding: '12px',
                            backgroundColor: 'rgba(255,85,85,0.1)',
                            borderRadius: '6px',
                            fontSize: '13px',
                            fontFamily: 'ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, monospace',
                        }}
                    />
                </LiveProvider>
            </div>
        </div>
    );
}

export default AutoDesignSystemPanel; 