import '../centerpanel.css';
import * as Popover from '@radix-ui/react-popover';
import { useEditorContext } from '../../../../context/editor/editorcontext';
import { useState } from 'react';
import DesignSystemIcon from '../../../../resources/editor/designSystemIcon.svg'
import { storybookService } from '../../../../utils/designSystems/storybook/storybookService';
import AddDS from './addDS/addDS';
import ChooseDS from './chooseDS/chooseDS';
import SettingsDS from './settingsDS/settingsDS';
import { getSystemComponents, getDesignSystemScope } from '../../../../utils/designSystems/loader/designSystemLoader';
import { LiveProvider, LivePreview } from 'react-live';
// Import MUI components for the scope
import { Button, TextField, IconButton, Card, CardContent, Typography, AppBar, Toolbar } from '@mui/material';
import { Menu as MenuIcon } from '@mui/icons-material';

function DesignSystemModal(){

    const editorContext = useEditorContext();
    const [openPanel, setOpenPanel] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);
    
    // Add debug log
    //console.log('selectedOption:', selectedOption);
    const components = getSystemComponents(selectedOption);
    const designSystemScope = getDesignSystemScope(selectedOption);  // Add this line

    //console.log('components:', components);

    const handleClick = (component) => {
        editorContext.addElement('designSystem', {
            systemId: selectedOption,
            component_name: component.name,
            defaultProps: component.defaultProps,
            code: component.codePreview
        });
        setOpenPanel(false);
    }

    return(
        <div className='cp-button cp-button-end-right'>
            <Popover.Root open={openPanel} onOpenChange={setOpenPanel}>
                <Popover.Trigger className='popoverTrigger'>
                    <img src={DesignSystemIcon} style={{width: 16,}}/>
                </Popover.Trigger>
                <Popover.Anchor />
                <Popover.Portal>
                    <Popover.Content className='elementsContainer'>
                        <div className='elementsSection'>
                            <div className='elementsSection-ds'>
                                <h4 style={{color:'#fff'}}>Design Systems</h4>
                                <div className='elementsSection-ds-option-buttons'>
                                    {/* <AddDS/> */}
                                    <ChooseDS setSelectedOption={setSelectedOption}/>
                                    {/* <SettingsDS/> */}
                                </div>
                            </div> 
                            <div>
                                <div className='elementsSectionContent'>
                                    {components.map((component) => (
                                        <div 
                                            key={component.name} 
                                            className="elementsSectionContainer" 
                                            style={{
                                                position: 'relative',
                                                cursor: 'pointer',
                                            }} 
                                            onClick={() => handleClick(component)}
                                        >
                                            <div className='element-preview-container'>
                                                <div style={{ 
                                                    padding: '8px',
                                                    height: '100px',
                                                    width: '100%',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    overflow: 'hidden'
                                                }}>
                                                    <div style={{
                                                        transform: 'scale(0.8)',
                                                        maxWidth: '100%',
                                                        maxHeight: '100%'
                                                    }}>
                                                        <LiveProvider 
                                                            code={component.codePreview} 
                                                            scope={designSystemScope}
                                                        >
                                                            <LivePreview />
                                                        </LiveProvider>
                                                    </div>
                                                </div>
                                                <h3>{component.name}</h3>
                                            </div> 
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </Popover.Content>
                </Popover.Portal>
            </Popover.Root>
        </div>
    );
}

export default DesignSystemModal;