import React, {useRef, useEffect, useLayoutEffect, useState, useMemo, memo} from "react";
import { Html } from 'react-konva-utils';
import { Stage, Layer, Rect, Text as KonvaText, Circle, Group, Transformer } from 'react-konva';
import { usePrototypeContext } from "../../../../context/prototype/prototypecontext";
import { prototypeSwitcher } from "../../utils/prototypeGenerator";
import { useMeasure } from "react-use";
import { Spring, animated, useSpring, useSpringValue } from '@react-spring/konva';
import CorePlayerText from "./core/coreplayertext";

function PlayerText({element, screenWidth, screenHeight, canvasX, canvasY, scale, stageY}) {

    const prototypeContext = usePrototypeContext(); 
 
    return(
        <>
            { 
                element.in_screen &&

                <CorePlayerText
                 key={element.id}
                 element={element}
                 screenWidth={screenWidth} 
                 screenHeight={screenHeight} 
                 canvasX={canvasX} 
                 canvasY={canvasY}
                 scale={scale}
                 stageY={stageY}
                />
            }
       </>
    )
}

export default memo(PlayerText);